import React from 'react';
import PropTypes from 'prop-types';

export default function BulmaInputText (props) {
  return (
    <div className="control">
      <input
        id={props.id}
        name={props.name}
        value={props.value}
        data-index={props.index}
        placeholder={props.placeholder}
        type="text"
        className={`input ${props.hasErrors ? 'is-danger' : ''}`}
        onChange={event => {
          props.handleChange(event);
        }}
        onBlur={event => props.handleBlur(event)}
      />
    </div>
  );
}

BulmaInputText.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  index: PropTypes.number,
  placeholder: PropTypes.string,
  hasErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func
};

BulmaInputText.defaultProps = {
  id: '',
  name: '',
  value: '',
  index: 0,
  placeholder: '',
  hasErrors: false,
  handleChange: (event) => console.log(event.target.value),
  handleBlur: (event) => console.log(event.target.value)
};
