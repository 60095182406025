import React from 'react';
import PropTypes from 'prop-types';
import {
  NestedComponent
} from '../molecules/index';
import {
  AbstractComponent
} from '../atoms/index';
import MustacheDoctor from './MustacheDoctor';
import MustacheSenbeiTag from './MustacheSenbeiTag';

export default class MustacheTroubleConsultationToDoctorModal extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      doctors: [],
      senbeiTags: []
    };
    this.handleChangeMustacheDoctors = this.handleChangeMustacheDoctors.bind(this);
    this.removeMustacheDoctor = this.removeMustacheDoctor.bind(this);
    this.handleChangeMustacheSenbeiTags = this.handleChangeMustacheSenbeiTags.bind(this);
    this.removeMustacheSenbeiTag = this.removeMustacheSenbeiTag.bind(this);
  }

  handleChangeMustacheDoctors (doctor, index) {
    const removeIndex = this.state.doctors.findIndex(doctor => doctor.index === index);
    const doctors = this.state.doctors;
    if (removeIndex >= 0) {
      doctors.splice(removeIndex, 1, doctor);
    } else {
      doctors.push(doctor);
    }
    this.setState({ doctors: doctors });
  }

  removeMustacheDoctor (index) {
    const removeIndex = this.state.doctors.findIndex(doctor => doctor.index === index);
    const doctors = this.state.doctors;
    if (removeIndex >= 0) {
      doctors.splice(removeIndex, 1);
      this.setState({ doctors: doctors });
    }
  }

  handleChangeMustacheSenbeiTags (senbeiTag, index) {
    const removeIndex = this.state.senbeiTags.findIndex(senbeiTag => senbeiTag.index === index);
    const senbeiTags = this.state.senbeiTags;
    if (removeIndex >= 0) {
      senbeiTags.splice(removeIndex, 1, senbeiTag);
    } else {
      senbeiTags.push(senbeiTag);
    }
    this.setState({ senbeiTags: senbeiTags });
  }

  removeMustacheSenbeiTag (index) {
    const removeIndex = this.state.senbeiTags.findIndex(senbeiTag => senbeiTag.index === index);
    const senbeiTags = this.state.senbeiTags;
    if (removeIndex >= 0) {
      senbeiTags.splice(removeIndex, 1);
      this.setState({ senbeiTags: senbeiTags });
    }
  }

  buildMustacheHtml () {
    if (this.state.doctors.length <= 0) return '';
    if (this.state.senbeiTags.length <= 0) return '';

    const consultationToDoctorJson = {
      doctors: buildMustacheDoctors(this.state.doctors),
      senbei_tags: buildMustacheSenbeiTags(this.state.senbeiTags)
    };
    const mustacheHtml = `<div>
                            {{TROUBLE_CONSULTATION_TO_DOCTORS||${window.btoa(JSON.stringify(consultationToDoctorJson))}}}
                          </div>
                          <p>
                            &nbsp;
                          </p>`;
    return mustacheHtml;

    // ムスタッシュに設定する医師情報を生成する
    function buildMustacheDoctors (doctors) {
      return doctors.reduce((accumulator, doctor, i) => {
        if (doctor.value) {
          accumulator.push({
            sort_order: i + 1,
            doctor_code: doctor.value,
            profile_image_size_type: doctor.profileImageSizeType
          });
        }
        return accumulator;
      }, []);
    }

    // ムスタッシュに設定するsenbeiタグ情報を生成する
    function buildMustacheSenbeiTags (senbeiTags) {
      return senbeiTags.reduce((accumulator, senbeiTag, i) => {
        if (senbeiTag.value) {
          accumulator.push({
            sort_order: i + 1,
            tag_code: senbeiTag.value
          });
        }
        return accumulator;
      }, []);
    }
  }

  render () {
    return (
      <div className={`modal ${this.props.isActive ? 'is-active' : ''}`} style={{ overflow: 'visible' }}>
        <div className="modal-background"></div>
        <div className="modal-content" style={{ overflow: 'visible' }}>
          <div className="card p-4" style={{ overflow: 'visible' }}>
            <div className="card-content">
              <label className="label">タグ</label>
              <NestedComponent
                component={MustacheSenbeiTag}
                commonProps={{
                  name: 'senbei_tag',
                  handleChangeMustacheSenbeiTags: this.handleChangeMustacheSenbeiTags
                }}
                callbackAtRemoveComponent={this.removeMustacheSenbeiTag}
              />
            </div>
            <div className="card-content">
              <label className="label">医師</label>
              <NestedComponent
                component={MustacheDoctor}
                commonProps={{
                  handleChangeMustacheDoctors: this.handleChangeMustacheDoctors
                }}
                callbackAtRemoveComponent={this.removeMustacheDoctor}
              />
            </div>
            <div className="card-content has-text-right">
              <button
                className="button is-primary"
                onClick={event => {
                  event.preventDefault();
                  const mustacheHtml = this.buildMustacheHtml();
                  if (!mustacheHtml) {
                    alert('タグと医師を設定してください');
                    return false;
                  }
                  this.props.insertHtmlToEditor(mustacheHtml);
                  this.props.handleClose();
                }}
              >
                送信
              </button>
            </div>
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={event => {
            event.preventDefault();
            this.props.handleClose();
          }}
        ></button>
      </div>
    );
  }
}

MustacheTroubleConsultationToDoctorModal.propTypes = {
  isActive: PropTypes.bool.isRequired, // モーダル表示有無
  handleClose: PropTypes.func.isRequired, // モーダルを閉じる関数
  insertHtmlToEditor: PropTypes.func.isRequired // CKEditorにhtmlを挿入する関数
};

MustacheTroubleConsultationToDoctorModal.defaultProps = {
  isActive: false
};
