import React from 'react';
import PropTypes from 'prop-types';
import styles from './Modal.module.scss';

export const ItemList = (props) => {
  const {
    name,
    code
  } = props;
  const isActiveKa = props.isActiveKa;
  const handleSelectDepartment = props.handleSelectDepartment;
  const listStyle = { display: isActiveKa ? 'block' : 'none' };
  const department = { name: name, code: code };

  return (
    <li className="p-hospitals-search__modal-contents-list-item js-search-ka" style={listStyle}>
      <a className="p-hospitals-search__modal-contents-list-link js-subject-input" onClick={() => handleSelectDepartment(department)}>{name}</a>
    </li>
  );
};

export const ModalItem = (props) => {
  const subTitle = props.subTitle;
  const itemList = props.itemList;
  const isActiveKa = props.isActiveKa;
  const subTitleStyle = { display: isActiveKa ? 'block' : 'none' };

  return (
    <>
      <p className="p-hospitals-search__modal-contents-subtitle js-search-ka" style={subTitleStyle}>{subTitle}</p>
      <ul className={`p-hospitals-search__modal-contents-list js-search-ka ${styles.modalListFlexbox}`}>
        {itemList.map((item) => {
          const [code, name] = item;
          return <ItemList key={code} name={name} code={code} isActiveKa={isActiveKa} handleSelectDepartment={props.handleSelectDepartment} />;
        })}
      </ul>

    </>
  );
};

ModalItem.propTypes = {
  subTitle: PropTypes.string,
  itemList: PropTypes.array,
  isActiveKa: PropTypes.bool,
  handleSelectDepartment: PropTypes.func
};

ItemList.propTypes = {
  name: PropTypes.string,
  code: PropTypes.string,
  handleSelectDepartment: PropTypes.func,
  isActiveKa: PropTypes.bool
};

export default ModalItem;
