import React from 'react';
import PropTypes from 'prop-types';

export default class NotLoggedInArticleBody extends React.Component {
  render () {
    return (
      <>
        <div className="pg-routable__login-wrap">
          <div className="c-mn-section-article">
            <p>{this.props.notLoggedInArticleBodyText}</p>
          </div>
        </div>
        <div className="pg-routable__login-btn-area">
          <a className="c-mn-btn--first" href=""><span>会員登録して実際の症例を見る</span></a>
          <p><a className="c-mn-link" href={this.props.loginPath}>ログインする</a></p>
        </div>
      </>
    );
  }
}

NotLoggedInArticleBody.propTypes = {
  notLoggedInArticleBodyText: PropTypes.string.isRequired,
  loginPath: PropTypes.string.isRequired
};
