import React from 'react';
import PropTypes from 'prop-types';

export default function BulmaImage (props) {
  return (
    <figure className={`image ${props.additionalClassName}`} style={props.figureStyle}>
      <img src={props.src} style={props.imgStyle} />
    </figure>
  );
}

BulmaImage.propTypes = {
  additionalClassName: PropTypes.string,
  figureStyle: PropTypes.object,
  imgStyle: PropTypes.object,
  src: PropTypes.string.isRequired
};

BulmaImage.defaultProps = {
  additionalClassName: '',
  figureStyle: {},
  imgStyle: {}
};
