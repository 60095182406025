import React from 'react';
import LogInConfirmComponent from '../../../../common/molecules/LogInConfirmComponent';
import logo from '../../../../../images/front/hospitals_search/logo';
import SearchBox from '../../../pc/molecules/common/SearchBox';
import search from '../../../../../images/front/hospitals_search/icon/search';
import NavMenu from '../../../../common/molecules/Header/NavMenu';
import { USER_PATH } from '../../../../common/constants';
import MypageIcon from '../../../../common/atoms/MypageIcon';
import NotificationIcon from '../../../../common/molecules/Header/NotificationIcon';

export default class Header extends LogInConfirmComponent {
  constructor (props) {
    super(props);
    this.state = {
      isActive: false, // ヘッダーメニューの開閉フラグ
      isOpenSearchBox: false
    };
    this.scrollTop = this.scrollTop.bind(this);
    this.onClickCloseSearchBox = this.onClickCloseSearchBox.bind(this);
    this.onClickSearchIcon = this.onClickSearchIcon.bind(this);
  }

  scrollTop () {
    return Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop);
  }

  onClickSearchIcon () {
    this.setState({
      isOpenSearchBox: !this.state.isOpenSearchBox
    });
  }

  onClickCloseSearchBox () {
    this.setState({
      isOpenSearchBox: false
    });
  }

  render () {
    return (
      <header className="l-header-mv__header">
        <div className="p-header-renewal _is_for_guests">
          <div className='p-header-renewal__upper'>
            <div className='p-header-renewal__inner'>
              <p className='p-header-renewal__upper-desc'>
                医師と患者をつなぐ医療・ヘルスケアプラットフォーム｜2,000以上の病気の情報と医師・病院情報を掲載
              </p>
            </div>
          </div>
          <div className="p-header-renewal__inner">
            <div className="p-header-renewal__heading">
              <a className="p-header-renewal__heading-link" href="/">
                <img src={ this.props.config.assetHost + logo } className="p-header-renewal__heading__logo" alt="logo"/>
              </a>
              <NavMenu config={this.props.config} />
            </div>
            <ul className="p-header-renewal__menu">
              {!this.isLoggedIn() &&
                <li className="p-header-renewal__menu-for-guests">
                  <a className="p-header-renewal__sign-in p-header-renewal__spacer" href="/forward_to_login">ログイン</a>
                  <a className="p-header-renewal__sign-up" href="/forward_to_signup">会員登録</a>
                </li>
              }
              {this.isLoggedIn() &&
                <li className="p-header-renewal__menu-member">
                  <NotificationIcon config={this.props.config} />
                </li>
              }
              <li className="p-header-renewal__menu-member">
                <div className="c-search-icon">
                  <img src={this.props.config.assetHost + search} onClick={this.onClickSearchIcon} />
                </div>
              </li>
              {this.isLoggedIn() &&
                <li className="p-header-renewal__menu-member-mypage">
                  <a className="c-mypage-icon" href={USER_PATH}>
                    <MypageIcon />
                  </a>
                </li>
              }
            </ul>
            <SearchBox isOpenSearchBox={this.state.isOpenSearchBox} onClickCloseSearchBox={this.onClickCloseSearchBox}/>
          </div>
        </div>
      </header>
    );
  }
}
