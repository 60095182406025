import React from 'react';
import PropTypes from 'prop-types';
import NestedComponent from '../molecules/NestedComponent';
import ArticleGroupSelect from '../organisms/ArticleGroupSelect';

export default class ArticleGroupSelectWrapper extends React.Component {
  render () {
    return (
      <NestedComponent
        component={ArticleGroupSelect}
        commonProps={{ options: this.props.options }}
        individualProps={this.props.relatedArticleGroups}
      />
    );
  }
}

ArticleGroupSelectWrapper.propTypes = {
  options: PropTypes.array.isRequired,
  relatedArticleGroups: PropTypes.array.isRequired
};

ArticleGroupSelectWrapper.defaultProps = {
};
