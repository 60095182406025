import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '../../atoms/SearchIcon';
import BaseSearchBox from '../../../../common/molecules/Header/BaseSearchBox';

export default class SearchBox extends BaseSearchBox {
  render () {
    return (
      <form className={`p-header-renewal__search js_search ${this.formClassName()}`}>
        <div className="p-header-renewal__search_inner">
          <div className="p-header-renewal__input">
            <input placeholder="メディカルノートを検索" type="search" value={this.state.searchWord} onChange={this.onChangeSearchWord}/>
            <SearchIcon />
          </div>
          <div className="p-header-renewal__submit">
            <button type="submit" onClick={this.onClickSearch}>検索</button>
          </div>
          <div className="p-header-renewal__cancel">
            <a className="js_search_close" onClick={this.props.onClickCloseSearchBox}>キャンセル</a>
          </div>
        </div>
      </form>
    );
  }
}

SearchBox.propTypes = {
  isOpenSearchBox: PropTypes.bool.isRequired,
  onClickCloseSearchBox: PropTypes.func.isRequired
};
