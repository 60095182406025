import React from 'react';
import LogInConfirmComponent from '../../../../common/molecules/LogInConfirmComponent';

export default class HeaderMenu extends LogInConfirmComponent {
  constructor (props) {
    super(props);
    this.state = {
      isLoggedIn: this.isLoggedIn() // ログイン判定
    };
  }

  renderLogInAndSignUpButtons () {
    if (!this.state.isLoggedIn) {
      return (
        <>
          <header className="p-sidemenu__header">
            <ul className="p-sidemenu__loginItems">
              <li className="p-sidemenu__loginItem">
                <a className="p-sidemenu__login" href="/forward_to_login">ログイン</a>
              </li>
              <li className="p-sidemenu__loginItem">
                <a className="p-sidemenu__signup" href="/forward_to_signup">会員登録</a>
              </li>
            </ul>
          </header>
        </>
      );
    }
  }

  renderMyPageButton () {
    if (this.state.isLoggedIn) {
      return (
        <>
          <section className="p-sidemenu__section">
            <ul className="p-sidemenu__items">
              <li className="p-sidemenu__item"><a className="p-sidemenu__link" href="/user">マイページ</a></li>
            </ul>
          </section>
        </>
      );
    }
  }

  renderUserSettingAndLogOutButtons () {
    if (this.state.isLoggedIn) {
      return (
        <>
          <section className="p-sidemenu__section">
            <h2 className="p-sidemenu__title">アカウント</h2>
            <ul className="p-sidemenu__items">
              <li className="p-sidemenu__item">
                <a className="p-sidemenu__link" href="https://medicalnote-id.jp/user/settings">会員情報</a>
                <a className="p-sidemenu__link--logout" href="/logout" data-method="delete">
                  ログアウト
                  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={13} viewBox="0 0 14 13"><path fill="#bababa" fillRule="evenodd" d="M91.059,651.2v-3.25L96,652.5l-4.941,4.55V653.8H86.118v-2.6h4.941ZM82,646v13h7.823v-1.3H83.235V647.3h6.588V646H82Z" transform="translate(-82 -646)" /></svg>
                </a>
              </li>
            </ul>
          </section>
        </>
      );
    }
  }

  renderTreatmentNoteButton () {
    const url = '/health_records';
    return (
      <a className="p-sidemenu__link GA_click_health_records_hospitals_menu" href={url}>治療ノート</a>
    );
  }

  formClassName () {
    return this.props.isOpenMenu ? '_menu_is_opened' : '_menu_is_closed';
  }

  render () {
    return (
      <>
        <div className={`${this.formClassName()}`}>
          <div className={'l-sidemenu js_sidemenu_layer'}>
            <div className="p-sidemenu__overlay" onClick={this.props.onClickCloseMenu}>
              <svg xmlns="http://www.w3.org/2000/svg" width={8} height={8} viewBox="0 0 8 8">
                <path id="icon_close.svg" d="M632.841,451.79l-2.851-2.85-2.851,2.851a0.672,0.672,0,0,1-.951-0.951l2.852-2.851-2.851-2.851a0.671,0.671,0,1,1,.95-0.949l2.851,2.85,2.85-2.85a0.672,0.672,0,0,1,.951.95l-2.851,2.85,2.85,2.851A0.671,0.671,0,1,1,632.841,451.79Z" transform="translate(-626 -444)" />
              </svg>
            </div>
            <div className="p-sidemenu">
              {this.renderLogInAndSignUpButtons()}
              <div className="p-sidemenu__main">
                {this.renderMyPageButton()}
                <section className="p-sidemenu__section">
                  <ul className="p-sidemenu__items">
                    {/* <li className="p-sidemenu__item"><a className="p-sidemenu__link" href="https://medicalnote-qa.jp">医療相談</a>{this.renderTreatmentNoteButton()}</li> */}
                    <li className="p-sidemenu__item"><a className="p-sidemenu__link" href="https://medicalnote-qa.jp">医療相談</a></li>
                  </ul>
                </section>
                <section className="p-sidemenu__section">
                  <h2 className="p-sidemenu__title">病気辞典</h2>
                  <ul className="p-sidemenu__items">
                    <li className="p-sidemenu__item"><a className="p-sidemenu__link" href="/diseases">病気を調べる</a><a className="p-sidemenu__link" href="/symptoms">症状を調べる</a><a className="p-sidemenu__link" href="/checkups">検査を調べる</a></li>
                  </ul>
                </section>
                <section className="p-sidemenu__section">
                  <h2 className="p-sidemenu__title">病院・医師</h2>
                  <ul className="p-sidemenu__items">
                    <li className="p-sidemenu__item"><a className="p-sidemenu__link" href="/hospitals">病院・クリニックを探す</a><a className="p-sidemenu__link" href="/doctors">医師を探す</a></li>
                  </ul>
                </section>
                <section className="p-sidemenu__section">
                  <h2 className="p-sidemenu__title">医療・病気について知る</h2>
                  <ul className="p-sidemenu__items">
                    <li className="p-sidemenu__item"><a className="p-sidemenu__link" href="/nj_articles">NEWS &amp; JOURNAL</a><a className="p-sidemenu__link" href="/cancerportal">がんポータル</a><a className="p-sidemenu__link" href="/features">疾患特集</a></li>
                  </ul>
                </section>
                {this.renderUserSettingAndLogOutButtons()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
