import React from 'react';
import PropTypes from 'prop-types';
import NestedComponent from '../molecules/NestedComponent';
import ArticleInstitutionInvolvingInfo from './ArticleInstitutionInvolvingInfo';

export default class ArticleInstitutionInvolvingInfoWrapper extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      commonProps: {
        institutionInvolvingTypeOptions: props.institutionInvolvingTypeOptions
      }
    };
  }

  render () {
    return (
      <NestedComponent
        component={ArticleInstitutionInvolvingInfo}
        componentName={'医療機関'}
        commonProps={this.state.commonProps}
        individualProps={this.props.institutionInvolvingInfos}
      />
    );
  }
}

ArticleInstitutionInvolvingInfoWrapper.propTypes = {
  institutionInvolvingTypeOptions: PropTypes.array,
  institutionInvolvingInfos: PropTypes.array
};

ArticleInstitutionInvolvingInfoWrapper.defaultProps = {
  institutionInvolvingInfos: []
};
