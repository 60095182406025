import React from 'react';
import PropTypes from 'prop-types';

export const FullScreenSpinner = (props) => {
  const { active } = props;

  return (
    <div className={`p-hospitals-search__modal-fullscreen-overlay ${active ? 'is-active' : ''}`}>
      <div className="p-hospitals-search__modal-loader"></div>
    </div>
  );
};

FullScreenSpinner.propTypes = {
  active: PropTypes.bool.isRequired
};

export default FullScreenSpinner;
