import React from 'react';

const MypageIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.57 92.5">
      <title>アセット 1</title>
      <g id="レイヤー_2" data-name="レイヤー 2">
        <g id="レイヤー_1-2" data-name="レイヤー 1">
          <path d="M84.52,87.66A42.78,42.78,0,0,0,54.4,53.25a28,28,0,1,0-24.23,0A42.78,42.78,0,0,0,.05,87.66a4.25,4.25,0,0,0,8.4,1.26,34.25,34.25,0,0,1,67.67,0,4.25,4.25,0,0,0,8.4-1.26ZM22.79,28a19.5,19.5,0,1,1,19.5,19.5A19.53,19.53,0,0,1,22.79,28Z"/>
        </g>
      </g>
    </svg>
  );
};

export default MypageIcon;
