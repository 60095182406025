import React from 'react';
import PropTypes from 'prop-types';

export default class NestedComponent extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      components: this.buildComponents(props.commonProps, props.individualProps)
    };
    this.addComponent = this.addComponent.bind(this);
  }

  buildComponents (commonProps, individualProps) {
    const components = [];
    for (let i = 0; i < individualProps.length; i++) {
      components.push({ ...commonProps, ...individualProps[i], destroy: false });
    }
    return components;
  }

  // 要素を追加する
  addComponent (event) {
    event.preventDefault();
    const activeComponentLength = this.state.components.filter(component => !component.destroy).length;
    if (this.props.maxNumberOfComponent && this.props.maxNumberOfComponent <= activeComponentLength) {
      alert(`追加できるのは${this.props.maxNumberOfComponent}件までです`);
      return;
    }

    this.setState(state => {
      state.components.push({ ...this.props.commonProps, destroy: false });
      return { components: state.components };
    });
  }

  // 要素を削除する
  removeComponent (event, i, callback) {
    event.preventDefault();
    this.setState(state => {
      state.components.splice(i, 1, { ...this.props.commonProps, destroy: true });
      return { components: state.components };
    });
    if (callback) callback(i);
  }

  render () {
    return (
      <>
        <ul>
          {this.state.components.map((component, i) => {
            return (
              <li
                key={i}
                className={`is-flex is-justify-content-space-between py-1 ${component.destroy ? 'is-hidden' : ''}`}
              >
                {React.createElement(this.props.component, { i: i, ...component })}
                {this.renderDeleteButtonIfNeed(i)}
              </li>
            );
          })}
        </ul>
        <div className="mt-2 is-flex is-justify-content-flex-end">
          <button className="button is-primary" onClick={this.addComponent}>
            {`${this.props.componentName ? `${this.props.componentName}を` : ''}追加する`}
          </button>
        </div>
      </>
    );
  }

  renderDeleteButtonIfNeed (i) {
    if (i === 0 && !this.props.isFirstDeleteButtonShow) {
      return null;
    }

    return (
      <button
        className="button is-danger"
        onClick={(event) => this.removeComponent(event, i, this.props.callbackAtRemoveComponent)}
      >
        削除する
      </button>
    );
  }
}

NestedComponent.propTypes = {
  component: PropTypes.any.isRequired, // 対象となるReact.Component
  componentName: PropTypes.string, // 対象となるReact.Component名（buttonで使用）
  commonProps: PropTypes.object, // 対象となるReact.Componentのprops（共通の属性）
  individualProps: PropTypes.array, // 対象となるReact.Componentのprops配列（個別の属性）
  callbackAtRemoveComponent: PropTypes.func, // removeComponentで実行するコールバック関数
  maxNumberOfComponent: PropTypes.number, // 対象となるReact.Componentの追加可能な最大数
  isFirstDeleteButtonShow: PropTypes.bool // 最初の領域に削除ボタンを表示するか
};

NestedComponent.defaultProps = {
  componentName: '',
  commonProps: {},
  individualProps: [],
  isFirstDeleteButtonShow: true
};
