import React from 'react';
import PropTypes from 'prop-types';

export default function IconClose (props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="c-mn-icon-close"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.handleClick}
    >
      <circle cx="12" cy="12" r="12" fill="#fff" className="c-mn-icon-close-x"></circle>
      <path
        d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM16.151 17.943L12.008 13.841L7.891 18L6.058 16.167L10.162 12.01L6 7.891L7.833 6.058L11.988 10.16L16.094 6L17.943 7.849L13.843 11.99L18 16.094L16.151 17.943Z"
        fill="#000"
        className="c-mn-icon-close-circle"></path>
      <circle cx="12" cy="12" r="11.5" stroke="#000" className="c-mn-icon-close-flame"></circle>
    </svg>
  );
}

IconClose.propTypes = {
  handleClick: PropTypes.func
};
