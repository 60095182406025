import React from 'react';
import LogInConfirmComponent from '../../../common/molecules/LogInConfirmComponent';

export default class SignUpBanner extends LogInConfirmComponent {
  render () {
    return (
      <>
        {this.isLoggedIn()
          ? null
          : <a href="/forward_to_signup">
            <img src={this.props.imgSrc} />
          </a>
        }
      </>
    );
  }
}
