import React from 'react';
import PropTypes from 'prop-types';
import LogInConfirmComponent from '../../../common/molecules/LogInConfirmComponent';

export default class StandardFooterRegistrationLink extends LogInConfirmComponent {
  render () {
    return (
      <>
        {!this.isLoggedIn() &&
          (
            <div className="l-sign-up">
              <div className="p-sign-up--institution-search p-sign-up">
                <div className="p-sign-up__inner p-sign-up__body">
                  <p className="p-sign-up__message p-sign-up__p">メディカルノートの会員になれば、<br/>医療に関する様々なサービスをさらにお使いいただけます。</p>
                  <div className="p-sign-up__btn"><a className="c-mn-btn--third" href={this.props.signUpPath}><span>会員登録する</span></a></div>
                </div>
              </div>
            </div>
          )
        }
      </>
    );
  }
}

StandardFooterRegistrationLink.propTypes = {
  signUpPath: PropTypes.string.isRequired
};
