import React from 'react';
import PropTypes from 'prop-types';
import {
  NestedComponent,
  ArticleFreeTextInvolvingInfo
} from '../molecules/index';

export default class ArticleFreeTextInvolvingInfos extends React.Component {
  render () {
    return (
      <NestedComponent
        component={ArticleFreeTextInvolvingInfo}
        componentName={'フリー設定'}
        commonProps={{ apiLocation: this.props.apiLocation }}
        individualProps={this.props.articleFreeTextInvolvingInfos}
      />
    );
  }
}

ArticleFreeTextInvolvingInfos.propTypes = {
  apiLocation: PropTypes.string.isRequired,
  articleFreeTextInvolvingInfos: PropTypes.array.isRequired
};
