import React from 'react';
import PropTypes from 'prop-types';

const CampaignCodeSearchCheckbox = (props) => {
  const { name, code, selectedCampaignCodeSearchFilterNames, handleCheckCampaignCodeSearchFilterNames } = props;
  const idName = `campaign_code_search_filter_code_${code}`;

  return (
    <dev className="p-hospitals-search__search-box-input-checkbox-wrap">
      <input className="p-hospitals-search__search-box-input-checkbox__check" id={idName} type="checkbox" checked={selectedCampaignCodeSearchFilterNames.some(item => item.name === name)} value={name} onChange={handleCheckCampaignCodeSearchFilterNames} />
      <label className="p-hospitals-search__search-box-input-checkbox__label" htmlFor={idName}>{name}</label>
    </dev>
  );
};

const CampaignCodeSearchFilterCheckboxField = (props) => {
  const { selectedCampaignCodeSearchFilterNames, handleCheckCampaignCodeSearchFilterNames, campaignCodeSearchFilters } = props;

  return (
    <div className="p-hospitals-search__search-box-input-text-wrap">
      <label className="c-form-label-responsive">絞り込み条件</label>
      <div className="p-hospitals-search__search-box-input-checkbox-inner">
        { campaignCodeSearchFilters.map(campaignCodeSearchFilter =>
          <CampaignCodeSearchCheckbox
            key={campaignCodeSearchFilter.id}
            name={campaignCodeSearchFilter.name}
            code={campaignCodeSearchFilter.code}
            selectedCampaignCodeSearchFilterNames={selectedCampaignCodeSearchFilterNames}
            handleCheckCampaignCodeSearchFilterNames={handleCheckCampaignCodeSearchFilterNames}
          />)
        }
      </div>
    </div>
  );
};

CampaignCodeSearchFilterCheckboxField.propTypes = {
  campaignCodeSearchFilters: PropTypes.array.isRequired,
  selectedCampaignCodeSearchFilterNames: PropTypes.array.isRequired,
  handleCheckCampaignCodeSearchFilterNames: PropTypes.func.isRequired
};

CampaignCodeSearchCheckbox.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  code: PropTypes.string,
  campaignCodeSearchFilters: PropTypes.array.isRequired,
  selectedCampaignCodeSearchFilterNames: PropTypes.array.isRequired,
  handleCheckCampaignCodeSearchFilterNames: PropTypes.func.isRequired
};

export default CampaignCodeSearchFilterCheckboxField;
