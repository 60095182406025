import React from 'react';
import CKEditor from 'ckeditor4-react';
import {
  AbstractComponent,
  BulmaInputText,
  BulmaModal,
  BulmaRadio,
  Thumbnail
} from '../atoms/index';
import {
  ArticleImageModal
} from '../molecules/index';
import MustacheArticleModal from './MustacheArticleModal';
import MustacheTroubleConsultationToDoctorModal from './MustacheTroubleConsultationToDoctorModal';

// MNのCDNに配置されているckeditor.jsを読み込む
CKEditor.editorUrl = 'https://cdn.medicalnote.jp/ckeditor/4.15.1/ckeditor.js';

export default class ArticleBodyEditor extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      modal: null, // 開くモーダルのid
      images: [], // アーティクル関連画像
      body: props.body?.body || '', // 本文
      editor: null, // CKEditor
      selectedImage: null, // 選択された画像
      width: '', // 幅
      height: '', // 高さ
      alt: '', // 代替テキスト
      align: '', // 行揃え
      caption: '' // キャプション
    };
    this.handleClose = this.handleClose.bind(this);
    this.search = this.search.bind(this);
    this.onChangeBodyByEditor = this.onChangeBodyByEditor.bind(this);
    this.onChangeBodyByTextArea = this.onChangeBodyByTextArea.bind(this);
    this.onBeforeLoad = this.onBeforeLoad.bind(this);
    this.searchByImageId = this.searchByImageId.bind(this);
  }

  handleClose () {
    this.setState({ modal: null });
  }

  search (managedName = null) {
    const apiLocation = managedName ? `${this.props.apiLocation}?managed_name=${managedName}` : this.props.apiLocation;
    this
      .fetch(apiLocation)
      .then(data => {
        this.setState({ images: data.data, modal: 1 });
      });
  }

  searchByImageId (articleImageId) {
    const apiLocation = `${this.props.apiLocation}?article_image_id=${articleImageId}`;
    this
      .fetch(apiLocation)
      .then(data => {
        this.setState({ images: data.data });
      });
  }

  onChangeBodyByEditor (event) {
    this.setState({
      body: event.editor.getData()
    });
  }

  onChangeBodyByTextArea (event) {
    this.setState({
      body: event.target.value
    });
  }

  onBeforeLoad (ckeditor) {
    if (ckeditor) {
      this.registerPlugins(ckeditor);
    }
  }

  // 自前のプラグインをckeditorオブジェクトに登録
  registerPlugins (ckeditor) {
    const plugins = this.getPlugins();

    if (plugins && ckeditor.plugins.registered) {
      plugins.forEach(plugin => {
        ckeditor.plugins.add(plugin.name, plugin.config);
      });
    }
  }

  // 使用する自前のプラグインの関数を呼び出す
  getPlugins () {
    return [
      this.writeRuby(),
      // TODO: 関数内でstateを変更するためにthis(本コンポーネントのclass自身)を渡してるけど、これでいい？
      //       ＊thisが別のモノになってしまうため
      this.addImages(this),
      this.addArticle(this),
      this.troubleConsultation(this)
    ];
  }

  // ルビをふる自前プラグイン（carbonから移植）
  writeRuby () {
    return {
      name: 'writeruby', // CKEditorタグのconfig属性内のextraPlugins内でこのnameを記述
      config: {
        icons: 'writeruby',
        init: function (editor) {
          editor.addCommand('WriteRuby', {
            exec: function (editor) {
              const selectedText = editor.getSelection().getSelectedText();
              if (selectedText.length === 0) {
                alert('ルビを振りたい範囲を選択してください');
                return false;
              }

              const formatMatch = selectedText.match(/^(.+?)(\(|（)(.+?)(\)|）)$/);
              if (!formatMatch) {
                alert('`漢字(ふりがな)`の形式で選択してください');
                return false;
              }
              const rubyText = '<ruby>' +
                                 formatMatch[1] +
                                 '<rp>(</rp>' +
                                 '<rt>' + formatMatch[3] + '</rt>' +
                                 '<rp>)</rp>' +
                              '</ruby>';

              editor.insertHtml(rubyText);
            }
          });
          editor.ui.addButton('writeruby', {
            label: 'Write ruby', // ボタンにカーソルを合わせた際に表示される文言
            command: 'WriteRuby', // addCommandの第1引数と合わせる
            toolbar: 'extraPlugins', // ツールバー内のボタンの表示位置を指定
            icon: 'icons/ruby.png' // アイコン画像
          });
        }
      }
    };
  }

  // ArticleImageUplodaerコンポーネントで投稿された画像を選択して本文内に挿入するプラグイン
  addImages (parentThis) {
    return {
      name: 'addImage', // CKEditorタグのconfig属性内のextraPlugins内でこのnameを記述
      config: {
        icons: 'add_image',
        init: function (editor) {
          editor.addCommand('addImage', {
            exec: function (editor) {
              // TODO: (1).editorオブジェクトをstateに詰めるのは力技だと思うのでもっとスマートにしたい
              //           ＊editorオブジェクトに対して、この関数の外でinsertHtml関数を使う必要があるため
              //       (2).stateを変更するためにthis(本コンポーネントのclass自身）を持ってきているけど、これでいい？
              ///          ＊addImage関数内だとthisが別のモノになってしまうため
              parentThis.setState({ editor: editor, searchWord: '', alt: '', width: '', height: '', align: '', caption: '' });
              parentThis.search();
            }
          });
          editor.ui.addButton('addImage', {
            label: 'Add Image', // ボタンにカーソルを合わせた際に表示される文言
            command: 'addImage', // addCommandの第1引数と合わせる
            toolbar: 'extraPlugins', // ツールバー内のボタンの表示位置を指定
            icon: 'icons/add_image.png' // アイコン画像
          });
        }
      }
    };
  }

  // アーティクルを選択して本文内にムスタッシュとして挿入するプラグイン
  addArticle (parentThis) {
    return {
      name: 'addArticle', // CKEditorタグのconfig属性内のextraPlugins内でこのnameを記述
      config: {
        icons: 'add_article',
        init: function (editor) {
          editor.addCommand('addArticle', {
            exec: function (editor) {
              // TODO: (1).editorオブジェクトをstateに詰めるのは力技だと思うのでもっとスマートにしたい
              //           ＊editorオブジェクトに対して、この関数の外でinsertHtml関数を使う必要があるため
              parentThis.setState({ modal: 3, editor: editor });
            }
          });
          editor.ui.addButton('addArticle', {
            label: 'Add Article', // ボタンにカーソルを合わせた際に表示される文言
            command: 'addArticle', // addCommandの第1引数と合わせる
            toolbar: 'extraPlugins', // ツールバー内のボタンの表示位置を指定
            icon: 'icons/add_article.png' // アイコン画像
          });
        }
      }
    };
  }

  // お悩む相談モジュールを本文内にムスタッシュとして挿入するプラグイン
  troubleConsultation (parentThis) {
    return {
      name: 'troubleConsultation', // CKEditorタグのconfig属性内のextraPlugins内でこのnameを記述
      config: {
        icons: 'add_article',
        init: function (editor) {
          editor.addCommand('troubleConsultation', {
            exec: function (editor) {
              // TODO: (1).editorオブジェクトをstateに詰めるのは力技だと思うのでもっとスマートにしたい
              //           ＊editorオブジェクトに対して、この関数の外でinsertHtml関数を使う必要があるため
              parentThis.setState({ modal: 4, editor: editor });
            }
          });
          editor.ui.addButton('troubleConsultation', {
            label: 'Trouble Consultation', // ボタンにカーソルを合わせた際に表示される文言
            command: 'troubleConsultation', // addCommandの第1引数と合わせる
            toolbar: 'extraPlugins', // ツールバー内のボタンの表示位置を指定
            icon: 'icons/trouble_consultation.png' // アイコン画像
          });
        }
      }
    };
  }

  buildInsertHtml () {
    if (this.state.selectedImage === null) {
      return '';
    }

    const img = document.createElement('img');
    img.setAttribute('src', this.state.selectedImage.src);
    img.setAttribute('data-article_image_id', this.state.selectedImage.id);
    if (this.state.alt) {
      img.setAttribute('alt', this.state.alt);
    }
    if (this.state.width) {
      img.setAttribute('width', this.state.width);
    }
    if (this.state.height) {
      img.setAttribute('height', this.state.height);
    }

    if (this.state.caption === '') {
      const p = document.createElement('p');
      switch (this.state.align) {
      case '':
        p.appendChild(img);
        return p.outerHTML;
      case 'l':
        img.style.float = 'left';
        p.appendChild(img);
        return p.outerHTML;
      case 'c':
        p.style.textAlign = 'center';
        p.appendChild(img);
        return p.outerHTML;
      case 'r':
        img.style.float = 'right';
        p.appendChild(img);
        return p.outerHTML;
      }
    } else {
      const figure = document.createElement('figure');
      const figcaption = document.createElement('figcaption');
      const div = document.createElement('div');
      figure.classList.add('image');
      figcaption.insertAdjacentHTML('afterbegin', this.state.caption);
      switch (this.state.align) {
      case '':
        figure.appendChild(img);
        figure.appendChild(figcaption);
        return figure.outerHTML;
      case 'l':
        figure.style.float = 'left';
        figure.appendChild(img);
        figure.appendChild(figcaption);
        return figure.outerHTML;
      case 'c':
        div.style.textAlign = 'center';
        figure.appendChild(img);
        figure.appendChild(figcaption);
        div.appendChild(figure);
        return div.outerHTML;
      case 'r':
        figure.style.float = 'right';
        figure.appendChild(img);
        figure.appendChild(figcaption);
        return figure.outerHTML;
      }
    }
  }

  // 子コンポーネントからthis.state.editorを変更するための関数
  insertHtmlToEditor (html) {
    this.state.editor.insertHtml(html);
  }

  render () {
    return (
      <>
        <CKEditor
          data={this.state.body}
          onChange={this.onChangeBodyByEditor}
          onBeforeLoad={this.onBeforeLoad}
          config={ {
            toolbar: [
              { name: 'source', items: ['Source'] },
              { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord'] },
              { name: 'undo', items: ['Undo', 'Redo'] },
              { name: 'insert', items: ['Find', 'Replace'] },
              { name: 'select', items: ['SelectAll'] },
              { name: 'scayt', items: ['Scayt'] },
              { name: 'links', items: ['Link', 'Unlink'] },
              { name: 'insert', items: ['SpecialChar'] },
              { name: 'list', items: ['NumberedList', 'BulletedList'] },
              { name: 'indent', items: ['Indent', 'Outdent'] },
              { name: 'blocks', items: ['Blockquote', 'CreateDiv'] },
              { name: 'justify', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
              { name: 'basicstyles', items: ['Styles', 'Format', 'FontSize'] },
              { name: 'colors', items: ['TextColor'] },
              { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript'] },
              { name: 'format', items: ['RemoveFormat'] },
              { name: 'extraPlugins', items: ['writeruby', 'addImage', 'addArticle', 'troubleConsultation'] }
            ],
            extraPlugins: [
              'writeruby',
              'addImage',
              'addArticle',
              'troubleConsultation'
            ],
            allowedContent: 'div(complement-content,complement-wrap,mosaic-wrap,_is_hidden,_js_alert){text-align,background,border,padding}[data-content-id,data-image-src]; p(related-content,complement-element,pg-routable__txt-list-merit,pg-routable__txt-list-demerit){background-color}; u span{color}; img(mosaic-image-body,caption-image-body)[!src,width,height,alt]{float}; a(*)[!href,title]; h2 h3 h4(complement-title); h5(pg-routable__title-merit,pg-routable__title-demerit); h6 sub sup s ol ul li blockquote small figure(image); figcaption ruby rp rt',
            contentsCss: [this.props.customCkeditorCssUrl, 'https://cdn.medicalnote.jp/ckeditor/4.15.1/contents.css'],
            stylesSet: [
              { name: 'background: pink', element: 'p', attributes: { class: 'pg-routable__txt-list-merit' } },
              { name: 'background: blue', element: 'p', attributes: { class: 'pg-routable__txt-list-demerit' } },
              { name: 'image: smile', element: 'h5', attributes: { class: 'pg-routable__title-merit' } },
              { name: 'image: angry', element: 'h5', attributes: { class: 'pg-routable__title-demerit' } },
              { name: 'Special Container', element: 'div', attributes: { style: 'background:#eeeeee; border:1px solid #cccccc; padding:5px 10px' } },
              { name: 'Small', element: 'small' }
            ],
            image_previewText: 'ここにプレビューを表示します'
          } }
        />
        <ArticleImageModal
          id={1}
          isActive={this.state.modal === 1}
          images={this.state.images}
          handleClose={this.handleClose}
          handleSelected={image => {
            this.setState({ selectedImage: image, modal: 2 });
          }}
          searchByImageId={(articleImageId) => this.searchByImageId(articleImageId)}
        />
        <BulmaModal id={2} isActive={this.state.modal === 2} handleClose={this.handleClose}>
          <div className="is-flex mb-2">
            <Thumbnail src={this.state.selectedImage?.src} />
            <div className="is-flex-grow-2 px-5">
              <div className="field mb-2">
                <label className="label">代替テキスト</label>
                <BulmaInputText
                  id="alt"
                  name="alt"
                  value={this.state.alt}
                  handleChange={event => this.setState({ alt: event.target.value })}
                />
              </div>
              <div className="is-flex mb-2">
                <div className="field mr-4">
                  <label className="label">幅</label>
                  <BulmaInputText
                    id="width"
                    name="width"
                    value={this.state.width}
                    handleChange={event => this.setState({ width: event.target.value })}
                  />
                </div>
                <div className="field">
                  <label className="label">高さ</label>
                  <BulmaInputText
                    id="height"
                    name="height"
                    value={this.state.height}
                    handleChange={event => this.setState({ height: event.target.value })}
                  />
                </div>
              </div>
              <div className="field mb-2">
                <label className="label">行揃え</label>
                <div className="control">
                  <BulmaRadio
                    name="align"
                    value={this.state.align}
                    radios={[{ label: 'なし', value: '' }, { label: '左', value: 'l' }, { label: '中央', value: 'c' }, { label: '右', value: 'r' }]}
                    handleChange={value => this.setState({ align: value })}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">キャプション</label>
                <BulmaInputText
                  id="caption"
                  name="caption"
                  value={this.state.caption}
                  handleChange={event => this.setState({ caption: event.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button
                className="button is-primary"
                onClick={event => {
                  event.preventDefault();
                  this.state.editor.insertHtml(this.buildInsertHtml());
                  this.setState({ modal: null, selectedImage: null });
                }}
              >
                送信
              </button>
            </div>
          </div>
        </BulmaModal>
        <MustacheArticleModal
          isActive={this.state.modal === 3} // this.state.modal == 3 でアクティブ
          handleClose={this.handleClose}
          insertHtmlToEditor={e => this.insertHtmlToEditor(e)}
        />
        <MustacheTroubleConsultationToDoctorModal
          isActive={this.state.modal === 4} // this.state.modal == 4 でアクティブ
          handleClose={this.handleClose}
          insertHtmlToEditor={e => this.insertHtmlToEditor(e)}
        />
        <input name='article[article_body_attributes][id]' value={this.props.body?.id} type='hidden' />
        <textarea
          name='article[article_body_attributes][body]'
          value={this.state.body}
          className='is-hidden'
          onChange={this.onChangeBodyByTextArea}
        />
      </>
    );
  }
}
