import React from 'react';
import 'whatwg-fetch';

export default class FetchPublicApiComponent extends React.Component {
  constructor (props) {
    super(props);
    this.fetch = this.fetch.bind(this);
  }

  fetch (url, params = {}) {
    const header = {
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*'
    };
    let body = params.body ? JSON.stringify(params.body) : params.body;
    // ファイルアップロード
    if (params.file) {
      body = new FormData();
      body.append('image', params.file);
      delete header['Content-Type'];
    }
    return fetch(url, {
      mode: 'cors',
      method: params.methods ? params.methods : 'GET',
      headers: header,
      body: body
    }).then(data => {
      // 正常系はbodyを返却
      if ([200, 201].indexOf(data.status) !== -1) {
        return data.json();
      }
      // その他のレスポンス
      return new Promise((resolve, reject) => {
        // 正常かつcontentが存在しない系
        if ([204].indexOf(data.status) !== -1) {
          return resolve(data);
        }
        // 異常系はレスポンスを返却
        reject(data);
      });
    });
  }
}
