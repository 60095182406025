import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../../common/atoms/AbstractComponent';
import MacaronAsyncSelect from '../atoms/MacaronAsyncSelect';

export default class ArticleSenbeiTagSelectWrapper extends AbstractComponent {
  render () {
    return (
      <>
        <input type="hidden" value={this.props.id} name={`article[article_senbei_tags_attributes][${this.props.i}][id]`} id={`article_article_senbei_tags_attributes_${this.props.i}_id`}></input>
        <input type="hidden" value={this.props.destroy} name={`article[article_senbei_tags_attributes][${this.props.i}][_destroy]`} id={`article_article_senbei_tags_attributes_${this.props.i}__destroy`}></input>
        <div className="mr-4" style={{ width: '100%' }}>
          <MacaronAsyncSelect
            suggestUrl={'/macaron/api/suggest/senbei_tags'}
            suggestUrlQueryKey={'name'}
            hiddenParamName={`article[article_senbei_tags_attributes][${this.props.i}][tag_code]`}
            value={ { label: this.props.name, value: this.props.tag_code } }
          />
        </div>
      </>
    );
  }
}

ArticleSenbeiTagSelectWrapper.propTypes = {
  id: PropTypes.number,
  tag_code: PropTypes.string,
  i: PropTypes.number.isRequired,
  destroy: PropTypes.bool.isRequired
};

ArticleSenbeiTagSelectWrapper.defaultProps = {
};
