import React from 'react';
import PropTypes from 'prop-types';

export default class BaseSearchBox extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      searchWord: ''
    };
    this.onChangeSearchWord = this.onChangeSearchWord.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
  }

  onChangeSearchWord (e) {
    this.setState({
      searchWord: e.currentTarget.value
    });
  }

  onClickSearch (e) {
    e.preventDefault();
    location.href = `/diseases/search?value=${encodeURIComponent(this.state.searchWord)}`;
  }

  formClassName () {
    return this.props.isOpenSearchBox ? '_is_open' : '';
  }
}

BaseSearchBox.propTypes = {
  isOpenSearchBox: PropTypes.bool.isRequired,
  onClickCloseSearchBox: PropTypes.func.isRequired
};
