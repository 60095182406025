import React from 'react';
import PropTypes from 'prop-types';

// TODO: 現状レスポンシブの対応がされていないが既存のPC用とSP用のclassを同時に定義することでとりあえず崩れないデザインにはなっている
export const DoctorSaveButtonComponent = (props) => {
  const isLoggedIn = props.isLoggedIn;

  return (
    <>
      {isLoggedIn &&
        <li className="p-header__menu-item p-lower-header__btn-item">
          <a className="p-header__menu-link p-lower-header__btn-link" href="/user/pinned/doctors">
            <svg className="c-mn-icon-save-o" width={14} height={20} viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className="c-mn-icon-save-o" fillRule="evenodd" clipRule="evenodd" d="M6.66667 15L13.3333 20V0H0V20L6.66667 15ZM1.66667 16.6667L6.66667 12.9167L11.6667 16.6667V1.66667H1.66667V16.6667Z" fill="#000" />
            </svg>
            <span>保存済み</span>
          </a>
        </li>
      }
    </>
  );
};

DoctorSaveButtonComponent.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
};

export default DoctorSaveButtonComponent;
