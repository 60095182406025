import {
  AbstractComponent
} from '../atoms/index';

export default class LogInConfirmComponent extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      isLoggedIn: null
    };
  }

  // ログイン状態を取得してstateに設定する
  componentDidMount () {
    const loggedInCookie = document.cookie.split(';').find(cookie => cookie.split('=')[0].trim() === '_logged_in');
    if (loggedInCookie) {
      this.setState({ isLoggedIn: true });
      return;
    }
    this.fetch('/public/api/logged_in_confirms').then(data => this.setState({ isLoggedIn: data.data.logged_in }));
  }

  // ログイン状態を返す
  isLoggedIn () {
    return this.state.isLoggedIn;
  }

  // 非ログイン時用のアーティクル本文を返す
  buildNotLoggedInArticleBodyText (articleBody) {
    return decodeURI(articleBody)
      .replace(/<[^>]+>/g, ' ') // HTMLタグをスペースに置換
      .replace(/\r?\n/g, '') // 改行を空文字に置換
      .replace(/(\s|&nbsp;)+/g, ' ') // 複数半角スペースを1つの半角スペースに置換
      .trim().substr(0, 120); // 120文字に切り取り
  }
}
