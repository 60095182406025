import React from 'react';
import PropTypes from 'prop-types';

export default class LoggedInArticleBody extends React.Component {
  render () {
    return (
      <>
        <hr className="c-mn-line"/>
        <div className="pg-routable__block is-open">
          <div className="c-mn-section-article">
            <div dangerouslySetInnerHTML={{ __html: this.props.articleBody }} />
          </div>
        </div>
      </>
    );
  }
}

LoggedInArticleBody.propTypes = {
  articleBody: PropTypes.string.isRequired
};
