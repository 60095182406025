import React from 'react';
import PropTypes from 'prop-types';
import MacaronAsyncSelect from '../atoms/MacaronAsyncSelect';

export default class SerialArticle extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      serialArticleId: this.props.id
    };
  }

  render () {
    return (
      <>
        <input type="hidden" value={this.state.serialArticleId} name={`serial[serial_articles_attributes][${this.props.i}][id]`} id={`serial_serial_articles_attributes_${this.props.i}_id`} />
        <input type="hidden" value={this.props.destroy} name={`serial[serial_articles_attributes][${this.props.i}][_destroy]`} id={`serial_serial_articles_attributes_${this.props.i}__destroy`} />
        <div className="mr-4" style={{ width: '100%' }}>
          <MacaronAsyncSelect
            value={this.props.selected_article}
            suggestUrl='/macaron/api/suggest/articles'
            hiddenParamName={`serial[serial_articles_attributes][${this.props.i}][article_id]`}
          />
        </div>
        <div className="mr-4">
          <input className="input" type="number" min="1" name={`serial[serial_articles_attributes][${this.props.i}][sort_order]`} id={`serial_serial_articles_attributes_${this.props.i}_sort_order`} defaultValue={this.props.sort_order} />
        </div>
      </>
    );
  }
}

SerialArticle.propTypes = {
  destroy: PropTypes.bool,
  id: PropTypes.number,
  sort_order: PropTypes.number,
  selected_article: PropTypes.object,
  i: PropTypes.number
};

SerialArticle.defaultProps = {
};
