export const DISEASES_PATH = '/diseases';
export const SYMPTOMS_PATH = '/symptoms';
export const CHECKUPS_PATH = '/checkups';
export const HOSPITALS_PATH = '/hospitals';
export const DOCTORS_PATH = '/doctors';
export const FEATURES_PATH = '/features';
export const NJ_ARTICLES_PATH = '/nj_articles';
export const CANCER_PORTAL_PATH = '/cancerportal';
export const USER_PATH = '/user';
export const NOTIFICATION_ICON_FEATURE_SWITCH_NAME = 'notification_icon'; // 通知アイコンのfeature switch名
export const NOTIFICATIONS_PATH = '/notifications'; // 通知一覧
