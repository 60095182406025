import React from 'react';
import PropTypes from 'prop-types';

export default function RequiredTag (props) {
  return (
    <span className="tag tag-required is-danger is-light">{props.value}</span>
  );
}

RequiredTag.propTypes = {
  value: PropTypes.string
};

RequiredTag.defaultProps = {
  value: '必須'
};
