import React from 'react';
import PropTypes from 'prop-types';
import NestedComponent from '../molecules/NestedComponent';
import SerialArticle from './SerialArticle';

export default class SerialArticleWrapper extends React.Component {
  render () {
    return (
      <div className="columns">
        <div className="column field">
          <div className="columns mb-0">
            <div className="column is-9">
              <label className="label">アーティクル</label>
            </div>
            <div className="column is-3">
              <label className="label">掲載順(昇順・重複不可)</label>
            </div>
          </div>
          {
            <NestedComponent
              component={SerialArticle}
              componentName={'アーティクル'}
              individualProps={this.props.selectedArticles}
            />
          }
        </div>
      </div>
    );
  }
}

SerialArticleWrapper.propTypes = {
  selectedArticles: PropTypes.array
};

SerialArticleWrapper.defaultProps = {
  selectedArticles: []
};
