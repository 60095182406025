import React from 'react';
import backIcon from '../../../../../images/front/hospitals_search/icon/back';
import closeIcon from '../../../../../images/front/hospitals_search/icon/close';
import closeIcon02 from '../../../../../images/front/hospitals_search/icon/close02';
import FetchPublicApiComponent from '../../../../common/atoms/FetchPublicApiComponent';
import pinIcon from '../../../../../images/front/hospitals_search/icon/pin';
import { JapaneseSyllabaryLines } from '../../atoms/hospitals/index';
import FullScreenSpinner from '../common/FullScreenSpinner';

class ModalArea extends FetchPublicApiComponent {
  constructor (props) {
    super(props);
    this.state = {
      isSelectedPrefecture: false,
      isSelectedTab: 'city', // 'city' or 'station
      cities: [],
      stations: [],
      inputAreaWord: '',
      inputStationWord: '',
      isActiveStation01: true
    };
    this.closeModalAreaFlow02 = this.closeModalAreaFlow02.bind(this);
    this.errorCurrentArea = this.errorCurrentArea.bind(this);
    this.handleSelectCurrentLocation = this.handleSelectCurrentLocation.bind(this);
    this.getCurrentPosition = this.getCurrentPosition.bind(this);
    this.setCurrentArea = this.setCurrentArea.bind(this);
    this.fetchCurrentLocationFromPosition = this.fetchCurrentLocationFromPosition.bind(this);
  }

  fetchCities = () => {
    const apiLocation = `${this.props.mixerHost}/cities?prefecture_code=${this.props.selectedPrefecture.code}`;
    this
      .fetch(apiLocation)
      .then(response => {
        this.setState(
          {
            cities: this.transformCityResponse(response.data),
            loading: false
          }
        );
      });
  }

  fetchStations = () => {
    const apiLocation = `${this.props.mixerHost}/station_lines?prefecture_code=${this.props.selectedPrefecture.code}`;
    this
      .fetch(apiLocation)
      .then(response => {
        this.setState(
          {
            stations: this.transformStationResponse(response.data),
            loading: false
          }
        );
      });
  }

  setPrefecture = () => {
    this.setState({ isSelectedPrefecture: true });
  }

  handleClickPrefecture = (prefecture) => {
    this.props.handleSelectPrefecture(prefecture);
    this.setState(
      {
        isSelectedPrefecture: true
      }, () => {
        this.fetchCities();
        this.fetchStations();
      }
    );
  }

  handleSelectTab = (tab) => {
    this.setState({ isSelectedTab: tab });
  }

  handleSelectCurrentLocation = () => {
    this.props.changeCurrentLocationLoading(true);

    this.getCurrentPosition();
  }

  getCurrentPosition () {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.setCurrentArea, this.errorCurrentArea);
    } else {
      // 現在位置を取得できない場合の処理
      this.errorCurrentArea();
    }
  }

  errorCurrentArea () {
    this.props.changeCurrentLocationLoading(false);
    alert('現在位置を取得できません');
  }

  setCurrentArea = (position) => {
    const data = position.coords;
    const latitude = data.latitude;
    const longitude = data.longitude;

    this.fetchCurrentLocationFromPosition(latitude, longitude);
  }

  fetchCurrentLocationFromPosition (latitude, longitude) {
    const apiLocation = `${this.props.carbonHost}/api/v2/donut/cities?latitude=${latitude}&longitude=${longitude}`;
    this.fetch(apiLocation)
      .then(response => {
        const locationJson = response.data;
        this.props.setCurrentLocation({ name: locationJson[0].prefecture_name, code: locationJson[0].prefecture_code }, { code: locationJson[0].city_code, name: locationJson[0].city_name });
      }).catch(() => {
        this.errorCurrentArea();
      });
  }

  closeModalAreaFlow02 (event) {
    this.setState({ isSelectedPrefecture: false });
  }

  onChangeInputArea = (event) => {
    this.setState({ inputAreaWord: event.target.value });
  }

  onChangeInputStation = (event) => {
    this.setState({ inputStationWord: event.target.value });
  }

  renderPrefecture = () => {
    return this.props.prefectureData.map((prefecture, index) => {
      return (
        <div key={index}>
          <p className="p-hospitals-search__modal-contents-subtitle-area">{prefecture.area}</p>
          <ul className="p-hospitals-search__modal-area-contents-flow01-prefecture-list">
            {
              prefecture.items.map((item, index) => {
                return (
                  <li key={index} className="p-hospitals-search__modal-area-contents-flow01-prefecture-list-item">
                    <a className="p-hospitals-search__modal-area-contents-flow01-prefecture-list-link" onClick={() => this.handleClickPrefecture(item)}>{item.name}</a>
                  </li>
                );
              })
            }
          </ul>
        </div>
      );
    });
  }

  katakanaToHiragana = (str) => {
    return str.replace(/[\u30A1-\u30F6]/g, function (match) {
      return String.fromCharCode(match.charCodeAt(0) - 0x60);
    });
  }

  transformCityResponse = (cities) => {
    const lineNames = ['あ', 'か', 'さ', 'た', 'な', 'は', 'ま', 'や', 'ら', 'わ'];

    const transformed = {};

    cities.forEach(city => {
      const cityNameHiragana = this.katakanaToHiragana(city.city_name_kana);
      const firstChar = cityNameHiragana.charAt(0);
      const japaneseSyllabaryLine = JapaneseSyllabaryLines.withoutOthers().find(line => line.regex.test(firstChar));
      const lineName = japaneseSyllabaryLine ? japaneseSyllabaryLine.lineName.charAt(0) : 'わ';
      if (!transformed[lineName]) {
        transformed[lineName] = {
          lineName: lineName,
          items: []
        };
      }

      transformed[lineName].items.push({
        name: city.city_name,
        nameKana: cityNameHiragana,
        code: city.city_code
      });
    });

    return lineNames.map(lineName => transformed[lineName] || { lineName: lineName, items: [] });
  }

  transformStationResponse = (stations) => {
    return stations.map(line => ({
      lineName: line.line_name,
      items: line.station_masters.map(station => ({
        name: station.station_name,
        nameKana: station.station_name_kana,
        code: station.station_code.toString()
      }))
    }));
  }

  searchCities = (query) => {
    const lineNames = ['あ', 'か', 'さ', 'た', 'な', 'は', 'ま', 'や', 'ら', 'わ'];

    const results = {};

    this.state.cities.forEach(group => {
      group.items.forEach(city => {
        if (city.nameKana.includes(query) || city.name.includes(query)) {
          const lineName = group.lineName;
          if (!results[lineName]) {
            results[lineName] = {
              lineName: lineName,
              items: []
            };
          }
          results[lineName].items.push(city);
        }
      });
    });
    return lineNames.map(lineName => results[lineName] || { lineName: lineName, items: [] });
  }

  searchStations = (keyword) => {
    const searchResults = this.state.stations.map(line => {
      const filteredItems = line.items.filter(item =>
        item.name.includes(keyword) ||
        this.katakanaToHiragana(item.nameKana).includes(keyword)
      );
      return {
        lineName: line.lineName,
        items: filteredItems
      };
    }).filter(line => line.items.length > 0);
    return searchResults;
  }

  renderCities = () => {
    const cities = this.state.inputAreaWord === '' ? this.state.cities : this.searchCities(this.state.inputAreaWord);

    if (cities.some(city => city.items.length > 0)) {
      return cities.map((city, index) => {
        if (city.items.length === 0) return null;

        return (
          <div className="p-hospitals-search__modal-area-contents-flow02-block" key={index}>
            <p className="p-hospitals-search__modal-contents-subtitle">{city.lineName}</p>
            <ul className="p-hospitals-search__modal-contents-list">
              {
                city.items.map((item, index) => {
                  return (
                    <li key={index} className="p-hospitals-search__modal-contents-list-item">
                      <a className="p-hospitals-search__modal-contents-list-link" onClick={() => this.props.handleSelectCity(item)}>{item.name}</a>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        );
      });
    } else {
      return (
        <p className="p-hospitals-search__modal-area-contents-flow02-result0" style={{ display: cities.length > 0 ? 'block' : 'none' }}>検索結果はありません</p>
      );
    }
  }

  renderStations = () => {
    const stations = this.state.inputStationWord === '' ? this.state.stations : this.searchStations(this.state.inputStationWord);

    if (stations.length > 0) {
      return stations.map((station, index) => {
        return (
          <div className="p-hospitals-search__modal-area-contents-flow02-block" key={index}>
            <p className="p-hospitals-search__modal-contents-subtitle">{station.lineName}</p>
            <ul className="p-hospitals-search__modal-contents-list">
              {
                station.items.map((item, index) => {
                  return (
                    <li key={index} className="p-hospitals-search__modal-contents-list-item">
                      <a className="p-hospitals-search__modal-contents-list-link" onClick={() => this.props.handleSelectStation({ ...item, lineName: station.lineName })}>{item.name}</a>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        );
      });
    } else {
      return (
        <p className="p-hospitals-search__modal-area-contents-flow02-result0" style={{ display: 'block' }}>検索結果はありません</p>
      );
    }
  }

  render () {
    const {
      isActive,
      closeModal
    } = this.props;

    const style = {
      display: isActive ? 'block' : 'none'
    };

    return (
      <div className="p-hospitals-search__modal-area" style={style}>
        <div className="p-hospitals-search__modal-area-header">
          <a className={`p-hospitals-search__modal-area-header-icon-back01-link${this.state.isSelectedPrefecture && this.props.selectedPrefecture?.name ? ' is-active' : ''}`} onClick={this.closeModalAreaFlow02}><img src={this.props.config.assetHost + backIcon} width="24" className="p-hospitals-search__modal-area-header-icon-back01" alt="" /><span className="p-hospitals-search__modal-area-header-icon-back01-txt">{this.props.selectedPrefecture?.name}</span></a>
          <span className="p-hospitals-search__modal-area-header-title">エリア・駅名を選択</span>
          <img src={this.props.config.assetHost + closeIcon} width="24" className="p-hospitals-search__modal-area-header-icon-close" onClick={closeModal} alt="" />
          <img src={this.props.config.assetHost + closeIcon02} width="32" className="p-hospitals-search__modal-area-header-icon-close-pc" onClick={closeModal} alt="" />
        </div>
        <div className="p-hospitals-search__modal-contents">
          <div className={`p-hospitals-search__modal-area-contents-flow01${this.state.isSelectedPrefecture && this.props.selectedPrefecture?.name ? '' : ' is-open'}`}>
            <div className="p-hospitals-search__modal-area-contents-flow01-current-location">
              <a className="p-hospitals-search__modal-area-contents-flow01-current-location-link js-modal-area-current-location" onClick={this.handleSelectCurrentLocation}><img src={this.props.config.assetHost + pinIcon} width="24" className="p-hospitals-search__modal-area-contents-flow01-current-location-icon" alt="" />現在地を選択</a>
              <FullScreenSpinner active={this.props.isCurretLocationLoading} />
            </div>
            <div className="p-hospitals-search__modal-area-contents-flow01-prefecture">
              {this.renderPrefecture()}
            </div>
          </div>
          <div className={`p-hospitals-search__modal-area-contents-flow02${this.state.isSelectedPrefecture && this.props.selectedPrefecture?.name ? ' is-open' : ''}`}>
            <p className="p-hospitals-search__modal-area-contents-flow02-title">{this.props.selectedPrefecture?.name}</p>
            <div className="p-hospitals-search__modal-contents-tab">
              <a className={`p-hospitals-search__modal-contents-tab-link${this.state.isSelectedTab === 'city' ? ' is-active' : ''}`} onClick={() => this.handleSelectTab('city')}>エリア</a>
              <a className={`p-hospitals-search__modal-contents-tab-link${this.state.isSelectedTab === 'station' ? ' is-active' : ''}`} onClick={() => this.handleSelectTab('station')}>駅名</a>
            </div>
            <div className={`p-hospitals-search__modal-area-contents-flow02-area${this.state.isSelectedTab === 'city' ? ' is-active' : ''}`}>
              <input className="c-input-text-responsive js-input-area" value={this.state.inputAreaWord} name="" id="" type="text" placeholder="市町村名で絞り込む" onChange={this.onChangeInputArea} />
              <div className="p-hospitals-search__modal-area-contents-flow02-block">
                <ul className="p-hospitals-search__modal-contents-list--col1">
                  <li className="p-hospitals-search__modal-contents-list-item"><a className="p-hospitals-search__modal-contents-list-link js-area-hokkaido" onClick={() => this.props.handleSelectEntirePrefecture()}>{this.props.selectedPrefecture?.name}全域</a></li>
                </ul>
              </div>
              {this.renderCities()}
            </div>
          </div>
          <div className={`p-hospitals-search__modal-area-contents-flow02-station${this.state.isSelectedTab === 'station' ? ' is-active' : ''}`}>
            <input className="c-input-text-responsive js-input-station" value={this.state.inputStationWord} name="" id="" type="text" placeholder="駅名で絞り込む" onChange={this.onChangeInputStation} />
            {this.renderStations()}
          </div>
        </div>
      </div>
    );
  }
}

export default ModalArea;
