import React from 'react';
import PropTypes from 'prop-types';

export default class ArticleChangeHistory extends React.Component {
  render () {
    return (
      <>
        <input type="hidden" value={this.props.id} name={`article[article_changed_histories_attributes][${this.props.i}][id]`} id={`article_article_changed_histories_attributes_${this.props.i}_id`} />
        <input type="hidden" value={this.props.destroy} name={`article[article_changed_histories_attributes][${this.props.i}][_destroy]`} id={`article_article_changed_histories_attributes_${this.props.i}__destroy`} />
        <div className="columns mb-0" style={{ width: '90%' }}>
          <div className="column is-3">
            <input type="datetime-local" className="input" name={`article[article_changed_histories_attributes][${this.props.i}][published_at]`} id={`article_article_changed_histories_attributes_${this.props.i}_published_at`} defaultValue={this.props.published_at} />
          </div>
          <div className="column is-9">
            <input className="input" name={`article[article_changed_histories_attributes][${this.props.i}][description]`} id={`article_article_changed_histories_attributes_${this.props.i}_description`} defaultValue={this.props.description} />
          </div>
        </div>
      </>
    );
  }
}

ArticleChangeHistory.propTypes = {
  destroy: PropTypes.bool,
  id: PropTypes.number,
  published_at: PropTypes.string,
  description: PropTypes.string,
  i: PropTypes.number
};
