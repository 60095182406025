import React from 'react';
import NestedComponent from '../molecules/NestedComponent';
import SiliconSelect from '../atoms/SiliconSelect';

export default class DummyNestedComponentWrapper extends React.Component {
  render () {
    const options = [{ label: 'tag1', value: 1 }, { label: 'tag2', value: 2 }, { label: 'tag3', value: 3 }];
    const commonProps = { options: options, handleChange: (value) => { console.log(value); } };
    const individualProps = [
      { value: 1 },
      { value: 2 },
      { value: 3 }
    ];

    return (
      <NestedComponent
        component={SiliconSelect}
        componentName={'タグ'}
        commonProps={commonProps}
        individualProps={individualProps}
      />
    );
  }
}
