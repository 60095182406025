import React from 'react';
import PropTypes from 'prop-types';
import NestedComponent from '../molecules/NestedComponent';
import ArticleMedicalInvolvingInfo from './ArticleMedicalInvolvingInfo';

export default class ArticleMedicalInvolvingInfoWrapper extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      commonProps: {
        medicalInvolvingTypeOptions: props.medicalInvolvingTypeOptions
      }
    };
  }

  render () {
    return (
      <NestedComponent
        component={ArticleMedicalInvolvingInfo}
        componentName={'医療関係者'}
        commonProps={this.state.commonProps}
        individualProps={this.props.medicalInvolvingInfos}
      />
    );
  }
}

ArticleMedicalInvolvingInfoWrapper.propTypes = {
  medicalInvolvingTypeOptions: PropTypes.array,
  medicalInvolvingInfos: PropTypes.array
};

ArticleMedicalInvolvingInfoWrapper.defaultProps = {
  medicalInvolvingInfos: []
};
