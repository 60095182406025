import React from 'react';
import PropTypes from 'prop-types';
import NestedComponent from '../molecules/NestedComponent';
import ArticleSenbeiTagSelectWrapper from '../molecules/ArticleSenbeiTagSelectWrapper';

export default class ArticleSenbeiTagComponentWrapper extends React.Component {
  render () {
    const commonProps = {};

    return (
      <NestedComponent
        component={ArticleSenbeiTagSelectWrapper}
        componentName={'タグ'}
        commonProps={commonProps}
        individualProps={this.props.tags}
      />
    );
  }
}

ArticleSenbeiTagComponentWrapper.propTypes = {
  tags: PropTypes.array.isRequired
};
