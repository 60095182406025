import React from 'react';
import PropTypes from 'prop-types';
import NestedComponent from '../molecules/NestedComponent';
import ArticleChangeHistory from './ArticleChangeHistory';

export default class ArticleChangeHistoryWrapper extends React.Component {
  render () {
    return (
      <div className="columns">
        <div className="column field">
          <div className="columns mb-0">
            <div className="column is-1">
              <label>更新日</label>
            </div>
            <div className="column is-3">
              <label>メッセージ</label>
            </div>
          </div>
          {
            <NestedComponent
              component={ArticleChangeHistory}
              componentName={'更新履歴'}
              individualProps={this.props.articleChangedHistories}
              commonProps={{ description: this.props.defaultChangedMessage, published_at: this.props.defaultPublishedAt }}
              isFirstDeleteButtonShow={false}
            />
          }
        </div>
      </div>
    );
  }
}

ArticleChangeHistoryWrapper.propTypes = {
  articleChangedHistories: PropTypes.array.isRequired,
  defaultPublishedAt: PropTypes.string.isRequired,
  defaultChangedMessage: PropTypes.string.isRequired
};
