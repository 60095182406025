import React from 'react';

export default function Spinner (props) {
  return (
    <div style={{
      animation: 'spin 0.5s infinite linear',
      border: '2px solid #dbdbdb',
      borderRadius: '9999px',
      borderRightColor: 'transparent',
      borderTopColor: 'transparent',
      display: 'block',
      height: '1rem',
      width: '1rem'
    }}>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
}
