import { AbstractComponent } from '../atoms/index';

export default class NearbyHospitalComponent extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      nearbyHospitals: [],
      resultSearchQuery: '',
      condition: {
        treatments: [],
        area: null,
        options: [],
        institutionNameOrDoctorName: props.searchCondition.institutionNameOrDoctorName
      }
    };

    this.fetchNearbyHospitals = this.fetchNearbyHospitals.bind(this);
  }

  fetchNearbyHospitals (parameter) {
    let queryParameter = parameter;
    // hidden form から送られてきた検索結果の institution_code を配列に格納
    const institutionResultIds = [];
    document.getElementsByName('institution_code').forEach((elem) => { institutionResultIds.push(elem.value); });

    // 検索結果が存在すれば、クエリパラメータに追加
    if (institutionResultIds.length) {
      let institutionParams = '';
      institutionResultIds.forEach((elem) => { institutionParams = institutionParams.concat(`&excluding_institution_codes[]=${elem}`); });
      queryParameter = parameter.concat(institutionParams);
    }

    const apiLocation = `${this.props.siliconHost}/nearby_hospitals${queryParameter}`;
    this
      .fetch(apiLocation)
      .then(data => {
        this.setState(
          {
            nearbyHospitals: data.hospitals,
            resultSearchQuery: data.result_search_query
          }
        );
      });
  }
}
