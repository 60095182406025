import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function BulmaSelect (props) {
  const [value, setValue] = useState(props.value);

  return (
    <div className="control">
      <div className={`select ${props.hasErrors ? 'is-danger' : ''}`}>
        <select
          id={props.id}
          name={props.name}
          value={value}
          data-index={props.index}
          onChange={event => {
            setValue(event.target.value);
            props.handleChange(event);
          }}
          onBlur={event => {
            props.handleBlur(event);
          }}
        >
          {props.options.map((option, i) => {
            return (
              <option key={i} value={option.value}>{option.label}</option>
            );
          })}
        </select>
      </div>
    </div>
  );
}

BulmaSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  index: PropTypes.number,
  options: PropTypes.array,
  hasErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func
};

BulmaSelect.defaultProps = {
  id: '',
  name: '',
  value: '',
  index: 0,
  options: [],
  hasErrors: false,
  handleChange: (event) => { console.log(event.target.value); },
  handleBlur: (event) => { console.log(event.target.value); }
};
