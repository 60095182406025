import React from 'react';
import PropTypes from 'prop-types';

export default function BulmaModal (props) {
  return (
    <div className={`modal ${props.isActive ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-content" style={props.modalStyle}>
        <div className="card p-4">
          {props.children}
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={event => {
          event.preventDefault();
          props.handleClose();
        }}
      ></button>
    </div>
  );
}

BulmaModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  modalStyle: PropTypes.object
};
