import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import {
  AbstractComponent,
  SiliconSelect
} from '../atoms/index';

export default class MustacheDoctor extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      doctor: {},
      profileImageSizeType: this.profileImageSizeType()[0].value
    };
    this.fetchOptions = this.fetchOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.props.handleChangeMustacheDoctors({
      index: this.props.i,
      profileImageSizeType: this.profileImageSizeType()[0].value
    }, this.props.i);
  }

  fetchOptions (inputValue) {
    return new Promise((resolve) => {
      const url = `/macaron/api/suggest/doctors?name=${inputValue}`;
      this.fetch(url).then(data => { resolve(data.data); });
    });
  }

  handleChange (value) {
    value.index = this.props.i;
    value.profileImageSizeType = this.state.profileImageSizeType;
    this.setState({ doctor: value });
    this.props.handleChangeMustacheDoctors(value, this.props.i);
  }

  profileImageSizeType () {
    return [
      { label: 'LARGE', value: 'large' },
      { label: 'SMALL', value: 'small' }
    ];
  }

  handleChangeProfileImageSizeType (value) {
    this.setState({ profileImageSizeType: value });
    const doctor = this.state.doctor;
    doctor.index = this.props.i;
    doctor.profileImageSizeType = value;
    this.props.handleChangeMustacheDoctors(doctor, this.props.i);
  }

  render () {
    return (
      <>
        <div className="mr-4" style={{ width: '100%' }}>
          <AsyncSelect
            loadOptions={this.fetchOptions}
            onChange={this.handleChange}
            value={this.state.doctor}
            defaultInputValue={this.state.doctor.label}
          />
        </div>
        <div className="mr-4">
          <SiliconSelect
            id='mustache_profile_image_size_type'
            name='mustache_profile_image_size_type'
            options={this.profileImageSizeType()}
            handleChange={event => this.handleChangeProfileImageSizeType(event)}
          />
        </div>
      </>
    );
  }
}

MustacheDoctor.propTypes = {
  i: PropTypes.number.isRequired,
  handleChangeMustacheDoctors: PropTypes.func.isRequired
};

MustacheDoctor.defaultProps = {
};
