import React from 'react';
import PropTypes from 'prop-types';
import {
  NestedComponent
} from '../molecules/index';
import {
  AbstractComponent,
  SiliconSelect
} from '../atoms/index';
import MustacheArticle from './MustacheArticle';

export default class MustacheArticleModal extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      suggestUrl: `${this.suggestBaseUrl()}?q=`,
      mustacheArticles: [],
      mustacheArticleTypeValue: this.mustacheArticleTypeOptions()[0].value
    };
    this.handleChangeMustacheArticles = this.handleChangeMustacheArticles.bind(this);
    this.handleChangeMustacheArticleType = this.handleChangeMustacheArticleType.bind(this);
    this.removeMustacheArticle = this.removeMustacheArticle.bind(this);
  }

  handleChangeMustacheArticles (mustacheArticle, index) {
    const removeIndex = this.state.mustacheArticles.findIndex(mustacheArticle => mustacheArticle.index === index);
    const mustacheArticles = this.state.mustacheArticles;
    if (removeIndex >= 0) {
      mustacheArticles.splice(removeIndex, 1, mustacheArticle);
    } else {
      mustacheArticles.push(mustacheArticle);
    }
    this.setState({ mustacheArticles: mustacheArticles });
  }

  removeMustacheArticle (index) {
    const removeIndex = this.state.mustacheArticles.findIndex(mustacheArticle => mustacheArticle.index === index);
    const mustacheArticles = this.state.mustacheArticles;
    if (removeIndex >= 0) {
      mustacheArticles.splice(removeIndex, 1);
      this.setState({ mustacheArticles: mustacheArticles });
    }
  }

  handleChangeMustacheArticleType (value) {
    this.setState({
      suggestUrl: buildSuggestUrl(this.suggestBaseUrl()),
      mustacheArticles: [],
      mustacheArticleTypeValue: value
    });

    // アーティクルサジェストAPIのURLを組み立てる
    function buildSuggestUrl (baseUrl) {
      const optionQueryParams = new URLSearchParams({ article_type: 'case_introduction' }).toString();
      return value === 'ARTICLE_SYOREI' ? `${baseUrl}?${optionQueryParams}&q=` : `${baseUrl}?q=`;
    }
  }

  mustacheArticleTypeOptions () {
    return [
      { label: '横長記事', value: 'HORIZONTAL_SIMPLE_RELATED_ARTICLE' },
      { label: '症例記事', value: 'ARTICLE_SYOREI' }
    ];
  }

  suggestBaseUrl () {
    return '/macaron/api/suggest/articles';
  }

  buildMustacheHtml () {
    if (this.state.mustacheArticles.length <= 0) return '';

    const mustacheArticleCodes = this.state.mustacheArticles.map(mustacheArticle => mustacheArticle.code).join(',');
    const mustacheHtml = `<div>
                            {{${this.state.mustacheArticleTypeValue}||${mustacheArticleCodes}}}
                          </div>
                          <p>
                            &nbsp;
                          </p>`;
    return mustacheHtml;
  }

  render () {
    return (
      <div className={`modal ${this.props.isActive ? 'is-active' : ''}`} style={{ overflow: 'visible' }}>
        <div className="modal-background"></div>
        <div className="modal-content" style={{ overflow: 'visible' }}>
          <div className="card p-4" style={{ overflow: 'visible' }}>
            <div className="card-content">
              <label className="label">挿入するアーティクルタイプ</label>
              <SiliconSelect
                id='mustache_article_type'
                name='mustache_article_type'
                options={this.mustacheArticleTypeOptions()}
                handleChange={event => this.handleChangeMustacheArticleType(event)}
              />
            </div>
            <div className="card-content">
              <label className="label">アーティクル</label>
              {this.state.mustacheArticleTypeValue === 'ARTICLE_SYOREI'
                ? (
                  <NestedComponent
                    component={MustacheArticle}
                    maxNumberOfComponent={2}
                    commonProps={{
                      suggestUrl: this.state.suggestUrl,
                      handleChangeMustacheArticles: this.handleChangeMustacheArticles
                    }}
                    callbackAtRemoveComponent={this.removeMustacheArticle}
                  />
                )
                : (
                  <MustacheArticle
                    i={0}
                    suggestUrl={this.state.suggestUrl}
                    handleChangeMustacheArticles={this.handleChangeMustacheArticles}
                  />
                )
              }
            </div>
            <div className="card-content has-text-right">
              <button
                className="button is-primary"
                onClick={event => {
                  event.preventDefault();
                  const mustacheHtml = this.buildMustacheHtml();
                  if (!mustacheHtml) {
                    alert('アーティクルを選択してください');
                    return false;
                  }
                  this.props.insertHtmlToEditor(mustacheHtml);
                  this.props.handleClose();
                }}
              >
                送信
              </button>
            </div>
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={event => {
            event.preventDefault();
            this.props.handleClose();
          }}
        ></button>
      </div>
    );
  }
}

MustacheArticleModal.propTypes = {
  isActive: PropTypes.bool.isRequired, // モーダル表示有無
  handleClose: PropTypes.func.isRequired, // モーダルを閉じる関数
  insertHtmlToEditor: PropTypes.func.isRequired // CKEditorにhtmlを挿入する関数
};

MustacheArticleModal.defaultProps = {
  isActive: false
};
