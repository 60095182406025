import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../../../images/front/hospitals_search/logo';

const HeaderLogo = (props) => {
  const { assetHost } = props.config;

  return (
    <div className="p-header-responsive__heading">
      <a href="/">
        <img src={assetHost + logo} width="130" className="p-header-responsive__logo" alt="logo" />
      </a>
    </div>
  );
};

HeaderLogo.propTypes = {
  config: PropTypes.object.isRequired
};

export default HeaderLogo;
