export default class Imgix {
  static UriConverter (imgixHost, url, option) {
    if (!imgixHost) {
      return url;
    }

    if (!url) {
      return null;
    }

    const path = new URL(url).pathname;
    const imgixUrl = `${imgixHost}${path}`;

    if (option) {
      const queryParam = new URLSearchParams(option).toString();
      return `${imgixUrl}?${queryParam}`;
    }

    return imgixUrl;
  }
}
