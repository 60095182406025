import PropTypes from 'prop-types';
import AbstractComponent from '../../common/atoms/AbstractComponent';

export default class AbstractInput extends AbstractComponent {
}

AbstractInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any
};

AbstractInput.defaultProps = {
  value: null
};
