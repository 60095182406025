import React from 'react';
import LogInConfirmComponent from '../../../../common/molecules/LogInConfirmComponent';
import logo from '../../../../../images/front/hospitals_search/logo';
import search from '../../../../../images/front/hospitals_search/icon/search';
import hamburger from '../../../../../images/front/hospitals_search/icon/hamburger';
import SearchBox from '../../../sp/molecules/common/SearchBox';
import NotificationIcon from '../../../../common/molecules/Header/NotificationIcon';
import HeaderMenu from '../../molecules/carbon_hospitals/HeaderMenu';

export default class Header extends LogInConfirmComponent {
  constructor (props) {
    super(props);
    this.state = {
      isOpenMenu: false,
      isOpenSearchBox: false
    };
    this.onClickCloseSearchBox = this.onClickCloseSearchBox.bind(this);
    this.onClickSearchIcon = this.onClickSearchIcon.bind(this);
    this.onClickMenu = this.onClickMenu.bind(this);
    this.onClickCloseMenu = this.onClickCloseMenu.bind(this);
    this.scrollTop = this.scrollTop.bind(this);
  }

  onClickSearchIcon () {
    this.setState({
      isOpenSearchBox: !this.state.isOpenSearchBox
    });
  }

  onClickCloseSearchBox () {
    this.setState({
      isOpenSearchBox: false
    });
  }

  onClickMenu () {
    this.setState({
      isOpenMenu: true
    });
    const scrollPositionHeaderMenu = this.scrollTop;
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('fixed');
    body.style.top = -scrollPositionHeaderMenu;
  }

  onClickCloseMenu () {
    this.setState({
      isOpenMenu: false
    });
    const scrollPositionHeaderMenu = this.scrollTop;
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('fixed');
    body.style.top = 0;
    window.scrollTo(0, scrollPositionHeaderMenu);
  }

  scrollTop () {
    return Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    );
  }

  render () {
    return (
      <>
        <header className="l-header js-header">
          <div className="p-header-renewal">
            <div className='p-header-renewal__upper'>
              <p className='p-header-renewal__upper-desc'>医師と患者をつなぐ医療・ヘルスケアプラットフォーム</p>
            </div>
            <h1>
              <div className="p-header-renewal__heading">
                <a className="p-header-renewal-link" href="/">
                  <img src={ this.props.config.assetHost + logo } alt="logo"/>
                </a>
              </div>
            </h1>
            {this.state.isOpenSearchBox
              ? <SearchBox isOpenSearchBox={this.state.isOpenSearchBox} onClickCloseSearchBox={this.onClickCloseSearchBox}/>
              : <ul className="p-header-renewal__menu">
                {!this.isLoggedIn() &&
                  <li className="p-header-renewal__menu-for-guests">
                    <a className="p-header-renewal__sign-in p-header-renewal__spacer" href="/forward_to_login">ログイン</a>
                    <a className="p-header-renewal__sign-up" href="/forward_to_signup">会員登録</a>
                  </li>
                }
                {this.isLoggedIn() &&
                <li className="p-header-renewal__menu-member">
                  <NotificationIcon config={this.props.config} />
                </li>
                }
                <li className="p-header-renewal__menu-member">
                  <div className="p-header-renewal__menu-search">
                    <img src={this.props.config.assetHost + search} onClick={this.onClickSearchIcon} />
                  </div>
                </li>
                <li className="p-header-renewal__menu-item p-header-renewal__burger">
                  <a className="c-hamburger js_toggle_burger_menu">
                    <img src={this.props.config.assetHost + hamburger} width="16" className="js-sidemenu" alt="" onClick={this.onClickMenu} />
                  </a>
                </li>
              </ul>
            }
          </div>
        </header>
        <HeaderMenu isOpenMenu={this.state.isOpenMenu} onClickCloseMenu={this.onClickCloseMenu} />
      </>
    );
  }
}
