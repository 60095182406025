import React from 'react';
import 'whatwg-fetch';
import PropTypes from 'prop-types';
import { NOTIFICATION_ICON_FEATURE_SWITCH_NAME, NOTIFICATIONS_PATH } from '../../constants';

export default class NotificationIcon extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      unreadNotificaion: false,
      notifications: [],
      isDisplayComponent: false
    };
    this.setUnreadNotification = this.setUnreadNotification.bind(this);
  }

  async componentDidMount () {
    // feature state取得
    const featureResponse = await this.getFeatureState(NOTIFICATION_ICON_FEATURE_SWITCH_NAME);
    const featureJson = await featureResponse.json();
    // レスポンスが200以外もしくはopenedではない場合は処理終了
    if (featureResponse.status !== 200 || featureJson[0] === undefined || featureJson[0].state !== 'opened') return;
    // 通知データ取得
    const notificationResponse = await this.getNotifications();
    // レスポンスが200以外は処理終了
    if (notificationResponse.status !== 200) return;
    const notificationJson = await notificationResponse.json();
    this.setState({
      notifications: notificationJson,
      isDisplayComponent: true
    }, () => this.setUnreadNotification());
  }

  async getFeatureState (featureName) {
    return await fetch(`${this.props.config.carbonHost}/api/v1/feature_switches/state?feature=${featureName}`, {
      mode: 'cors',
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  // 通知取得
  async getNotifications () {
    return await fetch(`${this.props.config.carbonHost}/api/v1/renewal/notifications`, {
      mode: 'cors',
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  // state: unreadNotificaion のセット
  // 取得したnotificationsの中にreadがfalseが一つでもあれば、trueにセット
  setUnreadNotification () {
    if (this.state.notifications.some((notification) => notification.read === false)) {
      this.setState({
        unreadNotificaion: true
      });
    }
  }

  render () {
    if (!this.state.isDisplayComponent) return null;
    return (
      <a className={`c-notification-icon ${this.state.unreadNotificaion ? '_is_updated' : ''}`} href={NOTIFICATIONS_PATH}>
        <svg data-name="通知アイコン" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 83.01 95.15">
          <title>通知</title>
          <path d="M91.11,81.6,82.67,64.31V47.88A32.35,32.35,0,0,0,63.51,18.19V15.92A13.5,13.5,0,0,0,50,2.42h0a13.49,13.49,0,0,0-13.5,13.5v2.26A32.3,32.3,0,0,0,17.33,47.85V64.31L8.91,81.6A4,4,0,0,0,10.74,87a4,4,0,0,0,1.76.41H37.44A12.82,12.82,0,0,0,50,97.57h0A12.82,12.82,0,0,0,62.54,87.36h25a4,4,0,0,0,4-4A3.93,3.93,0,0,0,91.11,81.6ZM57,79.35c-1.08,5.81-2.94,8-2.94,8a5.42,5.42,0,0,1-.65.8,4.81,4.81,0,0,1-7.45-.8h0s-1.86-2.19-2.94-8H18.9l4.44-9.09h.07l1.92-4.2V47.82A24.38,24.38,0,0,1,36.51,27.27a21.31,21.31,0,0,1,8-3.42V15.92a5.49,5.49,0,1,1,11,0v7.93a21.61,21.61,0,0,1,8,3.42A24.44,24.44,0,0,1,74.67,47.85V66l1.16,2.55L81.1,79.35Z" transform="translate(-8.5 -2.42)"/>
        </svg>
      </a>
    );
  }
}

NotificationIcon.propTypes = {
  config: PropTypes.shape({
    carbonHost: PropTypes.string.isRequired
  }).isRequired
};
