import React from 'react';
import PropTypes from 'prop-types';
import { AbstractInput } from './index';

export default class ArticleImageInputFile extends AbstractInput {
  constructor (props) {
    super(props);
    this.queues = [];

    this.onChange = this.onChange.bind(this);
  }

  onChange (event) {
    this.queues = [];

    [...event.target.files].forEach(file => {
      if (!this.validate(file)) {
        return;
      }
      this.queues.push({
        key: `${this.props.name}`,
        src: '',
        raw: file,
        info: {}
      });
    });

    this.props.openModal();
    this.uploadFiles();
  }

  validate (file) {
    const validTypes = ['image/png', 'image/jpeg', 'image/jpg']; // pngとjpgのみ
    const validSize = 1048576 * 100; // 100MB
    let isValid = true;

    if (validTypes.indexOf(file.type) === -1) {
      this.props.pushUploadErrors(`${file.name}は許可されていない種類のファイルです。`);
      isValid = false;
    }
    if (validSize < file.size) {
      this.props.pushUploadErrors(`${file.name}はファイルサイズ制限を超過しています。`);
      isValid = false;
    }
    return isValid;
  }

  uploadFiles () {
    return new Promise((resolve) => {
      const file = this.queues.shift();
      if (file) {
        this
          .upload(file)
          .finally(() => {
            if (this.queues.length === 0) {
              resolve();
            } else {
              this
                .uploadFiles()
                .then(() => {
                  resolve();
                });
            }
          });
      } else {
        resolve();
      }
    });
  }

  upload (file) {
    return new Promise((resolve, reject) => {
      this
        .fetch(this.props.apiLocation, {
          methods: 'POST',
          file: file.raw,
          body: {
            copyright_type: 'before_set_attributes'
          }
        }).then(data => {
          this.props.pushFile(data.data);
          resolve(file);
        }).catch(() => {
          reject(file);
        });
    });
  }

  render () {
    return (
      <input
        type="file"
        id={this.props.id}
        name={this.props.name}
        className={this.props.className}
        multiple={this.props.multiple}
        onChange={this.onChange}
      />
    );
  }
}

ArticleImageInputFile.propTypes = {
  ...AbstractInput.propTypes,
  className: PropTypes.string,
  apiLocation: PropTypes.string
};

ArticleImageInputFile.defaultProps = {
  multiple: true
};
