import React from 'react';
import PropTypes from 'prop-types';
import LogInConfirmComponent from '../../../../common/molecules/LogInConfirmComponent';

export default class Header extends LogInConfirmComponent {
  renderDoctorSaveButton () {
    if (this.isLoggedIn()) {
      return (
        <>
          <li className="p-header__menu-item">
            <a className="p-header__menu-link" href="/user/pinned/doctors">
              <svg className="c-mn-icon-save-o" width={14} height={20} viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="c-mn-icon-save-o" fillRule="evenodd" clipRule="evenodd" d="M6.66667 15L13.3333 20V0H0V20L6.66667 15ZM1.66667 16.6667L6.66667 12.9167L11.6667 16.6667V1.66667H1.66667V16.6667Z" fill="#000" />
              </svg>
              <span>保存済み</span>
            </a>
          </li>
        </>
      );
    }
  }

  // TODO: ヘッダーとヘッダーメニューが分かれている件が解決次第削除する
  renderHamburgerButton () {
    if (!this.props.disableHamburgerButton) {
      return (
        <>
          <li className="p-header__menu-item">
            <a className="p-header__menu-link js_toggle_burger_menu" href="javascript:void(0);">
              <svg className="c-mn-icon-menu" width={15} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="c-mn-icon-menu-path" d="M0.787109 1.42857C0.787109 0.639593 1.4267 0 2.21568 0H19.3585C20.1475 0 20.7871 0.639593 20.7871 1.42857C20.7871 2.21755 20.1475 2.85714 19.3585 2.85714H2.21568C1.4267 2.85714 0.787109 2.21755 0.787109 1.42857Z" fill="black" />
                <path className="c-mn-icon-menu-path" d="M0.787109 10C0.787109 9.21102 1.4267 8.57143 2.21568 8.57143H19.3585C20.1475 8.57143 20.7871 9.21102 20.7871 10C20.7871 10.789 20.1475 11.4286 19.3585 11.4286H2.21568C1.4267 11.4286 0.787109 10.789 0.787109 10Z" fill="black" />
                <path className="c-mn-icon-menu-path" d="M0.787109 18.5714C0.787109 17.7824 1.4267 17.1429 2.21568 17.1429H19.3585C20.1475 17.1429 20.7871 17.7824 20.7871 18.5714C20.7871 19.3604 20.1475 20 19.3585 20H2.21568C1.4267 20 0.787109 19.3604 0.787109 18.5714Z" fill="#000" />
              </svg>
              <span>メニュー</span>
            </a>
          </li>
        </>
      );
    }
  }

  render () {
    return (
      <>
        <header className="l-header js-header">
          <div className="p-header--institution-search">
            <div className="p-header__heading">
              <h1>
                <a className="p-header__heading-link" href="/">
                  <svg className="cp_logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 553.05 113.39">
                    <title>Medicla Note</title>
                    <g>
                      <path fill="#6b8292" d="M159.11,32.19c.29,4.61,2.16,11.1,6.92,20.54,4.11,8.14,7.13,14.26,8.29,18.15,4.68-9.15,10.37-20.75,13.25-27.81a21.56,21.56,0,0,0,1.8-7.78,17.66,17.66,0,0,0-.28-3.1h14.62v2.74c-3.82.29-5.62,1.8-5.62,5.26a31.27,31.27,0,0,0,.29,3.53L202,71.39c.65,5,3,6.7,7.5,7.2v2.74H187.72V78.59c4.39,0,6.34-2.37,6.34-6,0-1.87-1.37-12.53-3.39-29.39h-.14a145,145,0,0,1-9.37,20.89c-4.39,8.36-6.7,13.91-7.35,17.22h-3.39c-.57-3.67-3.09-9.51-7.78-18.73-4.75-9.37-7.78-16-9.15-20.54h-.14l-3.68,25.58a28,28,0,0,0-.36,4.18c0,4.54,1.88,6.41,6.7,6.77v2.74H139.15V78.59c4.11,0,6-2.09,6.78-7.35l4.1-26.8a20.92,20.92,0,0,0,.29-3.6c0-3.82-1.58-5.62-5.55-5.91V32.19Z" />
                      <path fill="#6b8292" d="M240.45,61.37h-23v.94c0,8.5,5.4,14,12.53,14a12.82,12.82,0,0,0,8.94-3.46l1.51,1.37c-3.1,5-7.85,7.85-13.4,7.85-8.5,0-15-6.77-15-16.64s6.49-17.36,15.21-17.36C235.26,48.12,240.31,54.82,240.45,61.37Zm-7.56-2.59c-.58-5.12-3.1-8-7.21-8s-7,2.81-7.78,8Z" />
                      <path fill="#6b8292" d="M275.9,31.26h1.8V74c0,2.09.79,3.6,2.81,3.6a6.85,6.85,0,0,0,2.38-.36v2.89l-9.44,2h-1.8v-5.4c-3.75,3.53-7.93,5.4-12,5.4-7.42,0-13.54-6.63-13.54-15.42,0-10,8-18.58,17.43-18.58a30.11,30.11,0,0,1,8.15,1.36V38.6l-5.26-2.88V34.07ZM263,77.3a10.66,10.66,0,0,0,8.65-4.11V62.67c0-6.85-4.11-11.46-9.59-11.46-5.33,0-9.94,5.12-9.94,11.6C252.12,70,256.16,77.3,263,77.3Z" />
                      <path fill="#6b8292" d="M296.86,48.12h1.8V73.19c0,3.46,1.66,5.48,4.47,5.55v2.59H287.42V78.74c3.39-.07,5.19-2.24,5.19-5V56l-5.48-2.74V51.65Zm2.38-13A3.87,3.87,0,0,1,295.35,39a3.83,3.83,0,0,1-3.89-3.89,3.89,3.89,0,1,1,7.78,0Z" />
                      <path fill="#6b8292" d="M335.84,54.6c0,2.16-1.08,3.46-2.74,3.46-2,0-2.52-1.08-3.53-3.32s-2.74-3.53-6.27-3.53c-6.12,0-10,4.76-10,11.68,0,6.7,4.39,13.54,11.81,13.54,4.4,0,7.21-1.44,9.23-4.61L336,72.9c-1.51,5.55-6.19,9.22-13.4,9.22-9.29,0-15.13-7.63-15.13-16.78,0-7,4.25-17.22,17-17.22C331.37,48.12,335.84,50.35,335.84,54.6Z" />
                      <path fill="#6b8292" d="M366.89,55.68V74.41c0,1.52.65,2.53,1.8,2.53a3.78,3.78,0,0,0,2.23-.8l.65,2.6c-2.09,2.37-3.82,3.38-5.83,3.38-2.81,0-4.54-1.94-4.9-5.18-4.11,3.74-7.14,5.18-10.67,5.18-4.61,0-8.07-3.17-8.07-7.71,0-5,4.11-7.49,10.23-9.65l8.51-3V56.76c0-3.46-3.39-5.55-6.63-5.55-2.81,0-5.55,1.73-5.55,3.39,0,1.15,1.73,3,1.73,4,0,.43-.15.64-.94,1l-3.17,1.52a4,4,0,0,1-.86.36c-.43,0-2.31-3.32-2.31-5.05,0-3.38,4-8.35,13.26-8.35C363.43,48.12,366.89,50.57,366.89,55.68ZM353.56,77.3c2.59,0,4.83-1.16,7.28-3.75v-9l-4.47,1.66c-5.76,2.16-7.85,3.75-7.85,6.34C348.52,75.35,350.46,77.3,353.56,77.3Z" />
                      <path fill="#6b8292" d="M386.55,73c0,3.17,1.09,5.55,4.47,5.77v2.59H375.53V78.74c3.68-.22,5-2.45,5-5.26V39l-5-2.74V34.57l9.22-3.31h1.8Z" />
                      <path fill="#6b8292" d="M417,32.19l36.82,37.32V46c0-6.34-1.88-11-8.58-11V32.19h19.39v2.74c-5.19,0-7.71,4.25-7.71,10.81V69c0,6,1.65,10.23,1.65,12.9h-4.75c-3.46-4.54-4-5.19-6-7.21L418.25,44.37V69.66c0,5.76,2.53,8.93,8.79,8.93v2.74h-20.6V78.59c6.77,0,8.72-2.45,8.72-7.27V41.05l-3.75-3.67a9.72,9.72,0,0,0-6.05-2.45V32.19Z" />
                      <path fill="#6b8292" d="M497.43,64.83c0,9.87-7.06,17.29-16.21,17.29s-16.64-7.78-16.64-17.22,7.13-16.78,16.14-16.78C490.44,48.12,497.43,55,497.43,64.83Zm-22.84,9.73c1.88,3.1,4.11,4.9,7.28,4.9,5.55,0,9.08-3.89,9.08-10.23A31.63,31.63,0,0,0,488.86,58c-1.73-4.33-4.61-7.21-8.57-7.21-6,0-8.87,3.89-8.87,11.31A25.85,25.85,0,0,0,474.59,74.56Z" />
                      <path fill="#6b8292" d="M511.62,43.14v5.77h9.8v3.6h-9.8V73.69c0,3.25,1.16,4,5.12,4a39.23,39.23,0,0,0,5-.36V80.9a55.16,55.16,0,0,1-9.44,1.22c-4.9,0-6.77-2.16-6.77-6.12V52.51h-5.4V50.57c2.81-1,5.47-2.89,9-7.43Z" />
                      <path fill="#6b8292" d="M553.05,61.37h-23v.94c0,8.5,5.4,14,12.53,14a12.82,12.82,0,0,0,8.94-3.46l1.51,1.37c-3.1,5-7.85,7.85-13.4,7.85-8.5,0-15-6.77-15-16.64s6.49-17.36,15.2-17.36C547.86,48.12,552.91,54.82,553.05,61.37Zm-7.57-2.59c-.57-5.12-3.09-8-7.2-8-4,0-7,2.81-7.78,8Z" />
                      <path fill="#44abcb" fillRule="evenodd" d="M56.69,0A56.7,56.7,0,1,1,0,56.69,56.7,56.7,0,0,1,56.69,0Z" />
                      <path fill="#b3dee8" d="M84.63,81.42,30.19,39.21a2,2,0,0,0-2.11-.42,1.87,1.87,0,0,0-1.19,1.79V81.42H21.73V40.58A7,7,0,0,1,26.11,34a7.12,7.12,0,0,1,7.59,1.41l58,44.46v1.53Z" />
                      <path fill="#fff" d="M91.65,79.89V40.05a7.09,7.09,0,0,0-4.43-6.65c-2.45-1-4.59-.66-7.7,1.43L56.71,49.91l4.36,3.34L83.15,38.53a2.32,2.32,0,0,1,2.33-.34,1.79,1.79,0,0,1,1,1.74v36Z" />
                    </g>
                  </svg>
                  <span>病院検索β</span>
                </a>
              </h1>
            </div>
            <ul className="p-header__menu">
              {this.renderDoctorSaveButton()}
              {this.renderHamburgerButton()}
            </ul>
          </div>
        </header>
      </>
    );
  }
}

Header.propTypes = {
  disableHamburgerButton: PropTypes.bool.isRequired
};

Header.defaultProps = {
  disableHamburgerButton: false
};
