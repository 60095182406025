import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import AbstractComponent from '../../common/atoms/AbstractComponent';

export default class MacaronAsyncSelect extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      value: props.value || ''
    };
    this.fetchOptions = this.fetchOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  fetchOptions (inputValue) {
    return new Promise((resolve) => {
      const url = `${this.props.suggestUrl}?${this.props.suggestUrlQueryKey}=${inputValue}`;
      this.fetch(url).then(data => { resolve(data.data); });
    });
  }

  handleChange (value) {
    this.setState({ value });
  }

  render () {
    return (
      <>
        <AsyncSelect
          loadOptions={this.fetchOptions}
          onChange={this.handleChange}
          value={this.state.value}
          cacheOptions
          defaultValue={this.state.value}
        />
        <input
          type='hidden'
          name={this.props.hiddenParamName}
          value={this.state.value.value}
        />
      </>
    );
  }
}

MacaronAsyncSelect.propTypes = {
  suggestUrl: PropTypes.string.isRequired, // サジェストAPIのエンドポイント
  suggestUrlQueryKey: PropTypes.string, // サジェストAPIでリクエストするワードのクエリストリングキー(未指定の場合のデフォルトは"q")
  hiddenParamName: PropTypes.string.isRequired, // react-select/asyncで設定した値を送るためのhiddenパラメータ名
  value: PropTypes.any // AsyncSelectコンポーネントのvalue。"{label: '表示テキスト', value: 対象モデルのID}" のフォーマットで指定
};

MacaronAsyncSelect.defaultProps = {
  suggestUrlQueryKey: 'q'
};
