import React from 'react';

export default function IconSearch (props) {
  return (
    <svg className="c-mn-icon-search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.95 21.14" width="15">
      <path
        className="c-mn-icon-search-path"
        d="M20.08,21.54a1.26,1.26,0,0,1-.89-.37l-3.93-3.93-.27.19a9.21,9.21,0,0,1-5.26,1.63,9.33,9.33,0,1,1,9.33-9.33,9.25,9.25,0,0,1-1.78,5.48l-.2.27,3.9,3.9a1.27,1.27,0,0,1-.9,2.16ZM9.73,2.45A7.28,7.28,0,1,0,17,9.73,7.29,7.29,0,0,0,9.73,2.45Z"
        transform="translate(-0.4 -0.4)">
      </path>
    </svg>
  );
}
