import React from 'react';
import {
  AbstractComponent,
  SiliconSelect,
  Thumbnail
} from '../atoms/index';
import { ArticleImageModal } from './index';

export default class ArticleFreeTextInvolvingInfo extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      isActive: false, // アーティクル関連画像モーダルの開閉フラグ
      images: [], // アーティクル関連画像
      name: props.name || '', // 名前
      description: props.description || '', // 説明文
      linkUrl: props.link_url || '', // 遷移先
      articleImage: props.article_image || null, // アーティクル関連画像
      involvingType: props.involving_type || '', // 監修など関わりの形態
      importanceScore: props.importance_score || '' // 対象フリー設定の重要度
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.search = this.search.bind(this);
  }

  handleOpen () {
    this.setState({ isActive: true });
  }

  handleClose (event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({ isActive: false });
  }

  search (managedName = null) {
    const apiLocation = managedName ? `${this.props.apiLocation}?managed_name=${managedName}` : this.props.apiLocation;
    this
      .fetch(apiLocation)
      .then(data => {
        this.setState({ images: data.data });
      });
  }

  renderDestroyButton () {
    if (this.state.articleImage && this.state.articleImage.id) {
      return (
        <div className="field mb-2">
          <div className="control">
            <button
              className="button is-danger"
              onClick={event => {
                event.preventDefault();
                this.setState({ articleImage: null });
              }}
            >
              削除
            </button>
          </div>
        </div>
      );
    }
    return null;
  }

  render () {
    return (
      <div className="columns" style={{ width: '85%' }}>
        <div className="column" style={{ flexGrow: '1' }}>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label>画像</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="mb-2">
                  <Thumbnail src={this.state.articleImage?.src || ''} />
                </div>
                {this.renderDestroyButton()}
                <div className="file">
                  <label className="file-label">
                    <button
                      className="file-input"
                      onClick={event => {
                        event.preventDefault();
                        this.setState({ isActive: true, searchWord: '' });
                        this.search();
                      }}
                    >
                    </button>
                    <span className="file-cta">
                      <span className="file-icon">
                        <i className="fas fa-cloud-download-alt"></i>
                      </span>
                      <span className="file-label">画像を選択して下さい</span>
                    </span>
                  </label>
                </div>
                <ArticleImageModal
                  isActive={this.state.isActive}
                  images={this.state.images}
                  handleClose={this.handleClose}
                  handleSelected={image => {
                    this.setState({
                      articleImage: {
                        id: image.id,
                        src: image.thumbnail_src
                      },
                      isActive: false
                    });
                  }}
                />
                <input
                  type="hidden"
                  name={`article[article_free_text_involving_infos_attributes][${this.props.i}][article_image_id]`}
                  value={this.state.articleImage?.id || ''}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="column" style={{ flexGrow: '1.5' }}>
          <div className="field is-horizontal mb-2">
            <div className="field-label is-normal">
              <label>遷移先</label>
            </div>
            <div className="field-body">
              <div className="field">
                <input
                  name={`article[article_free_text_involving_infos_attributes][${this.props.i}][link_url]`}
                  value={this.state.linkUrl}
                  type="text"
                  className="input"
                  onChange={event => this.setState({ linkUrl: event.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="field is-horizontal mb-2">
            <div className="field-label is-normal">
              <label>説明文</label>
            </div>
            <div className="field-body">
              <div className="field">
                <input
                  name={`article[article_free_text_involving_infos_attributes][${this.props.i}][description]`}
                  value={this.state.description}
                  type="text"
                  className="input"
                  onChange={event => this.setState({ description: event.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="field is-horizontal mb-2">
            <div className="field-label is-normal">
              <label>名前</label>
            </div>
            <div className="field-body">
              <div className="field">
                <input
                  name={`article[article_free_text_involving_infos_attributes][${this.props.i}][name]`}
                  value={this.state.name}
                  type="text"
                  className="input"
                  onChange={event => this.setState({ name: event.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="field is-horizontal mb-2">
            <div className="field-label is-normal">
              <label>関連種別</label>
            </div>
            <div className="field-body">
              <div className="field">
                <SiliconSelect
                  id={`article_article_free_text_involving_infos_attributes_${this.props.i}_involving_type`}
                  name={`article[article_free_text_involving_infos_attributes][${this.props.i}][involving_type]`}
                  options={[{ label: '取材', value: 'interview' }, { label: '監修', value: 'supervisor' }]}
                  value={this.state.involvingType}
                />
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label>重要度</label>
            </div>
            <div className="field-body">
              <div className="field">
                <input
                  type="number"
                  id={`article_article_free_text_involving_infos_attributes_${this.props.i}_importance_score`}
                  name={`article[article_free_text_involving_infos_attributes][${this.props.i}][importance_score]`}
                  defaultValue={this.state.importanceScore}
                  className="input"
                  placeholder="数字が大きいほど重要"
                  min="0"
                />
              </div>
            </div>
          </div>
        </div>
        <input
          type="hidden"
          name={`article[article_free_text_involving_infos_attributes][${this.props.i}][id]`}
          value={this.props?.id}
        />
        <input
          type="hidden"
          name={`article[article_free_text_involving_infos_attributes][${this.props.i}][_destroy]`}
          value={!!this.props.destroy}
        />
      </div>
    );
  }
}
