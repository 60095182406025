import React from 'react';
import BaseSearchBox from './BaseSearchBox';
import questionIcon from '../../../../../images/front/hospitals_search/icon/question';
import deleteIcon from '../../../../../images/front/hospitals_search/icon/delete';
import invertedTriangleIcon from '../../../../../images/front/hospitals_search/icon/inverted_triangle';
import arrowDownIcon from '../../../../../images/front/hospitals_search/icon/arrow_down';
import searchWhite from '../../../../../images/front/hospitals_search/icon/search_white';
import ModalArea from '../../molecules/hospitals/ModalArea';
import ModalDiseases from '../../molecules/hospitals/ModalDiseases';
import CampaignCodeSearchFilterCheckboxField from '../../molecules/hospitals/CampaignCodeSearchFilterCheckboxField';
import FullScreenSpinner from '../../molecules/common/FullScreenSpinner';

class SearchBox extends BaseSearchBox {
  componentDidMount () {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('load', this.handleResize);
    this.handleResize(); // 初期サイズを処理
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('load', this.handleResize);
  }

  handleResize () {
    const winW = window.innerWidth;
    const devW = 768;
    if (devW <= winW) {
      const jsSearchBox = document.querySelector('.js-search-box');
      const searchBox = document.querySelector('.p-hospitals-search__search-box');
      if (jsSearchBox) jsSearchBox.classList.remove('is-active');
      if (searchBox) searchBox.removeAttribute('style');
    }
  }

  jsSearchBox = (event) => {
    if (event) {
      event.preventDefault();
    }
    const jsSearchBox = document.getElementsByClassName('js-search-box')[0];
    const target = jsSearchBox.nextElementSibling;
    jsSearchBox.classList.toggle('is-open');
    target.classList.toggle('is-open');

    if (target.classList.contains('is-open')) {
      target.style.height = target.scrollHeight + 40 + 'px';
    } else {
      target.style.height = '0';
    }
  }

  searchTextForm = () => {
    return (
      <>
        <div className="p-hospitals-search__search-box-input-text-wrap">
          <label className="c-form-label-responsive">エリア・駅名</label>
          <div className="p-hospitals-search__search-box-input-text-inner">
            <input className="c-input-text-responsive js-modal-area-open" name="area" id="area" type="text" readOnly placeholder="エリア・駅名を選択" value={this.selectedArea()} onClick={this.openAreaModal} />
            <img src={this.props.config.assetHost + deleteIcon} width="20" className={`p-hospitals-search__search-box-input-text-delete${this.state.selectedPrefecture.name || this.state.selectedCity.name || this.state.selectedStation.name ? ' is-active' : ''}`} alt="" onClick={this.clearSelectedArea} />
            <img src={this.props.config.assetHost + invertedTriangleIcon} width="10" className={`p-hospitals-search__search-box-input-text-select${this.state.selectedPrefecture.name || this.state.selectedCity.name || this.state.selectedStation.name ? '' : ' is-active'}`} alt="" onClick={this.openAreaModal} />
          </div>
        </div>
        {this.state.campaignCodeSearchFilterNamesChoices.length > 0 && this.props.position === 'side' &&
          <CampaignCodeSearchFilterCheckboxField
            campaignCodeSearchFilters={this.state.campaignCodeSearchFilterNamesChoices}
            selectedCampaignCodeSearchFilterNames={this.state.selectedCampaignCodeSearchFilterNames}
            handleCheckCampaignCodeSearchFilterNames={this.handleCheckCampaignCodeSearchFilterNames}
          />
        }
        <div className="p-hospitals-search__search-box-input-text-wrap">
          <label className="c-form-label-responsive">病名 / 診療科目</label>
          <div className="p-hospitals-search__search-box-input-text-inner">
            <input className="c-input-text-responsive js-modal-diseases-open" name="diseases" id="diseases" type="text" readOnly placeholder="病名 /  診療科目を選択" value={this.state.selectedDisease.name || this.state.selectedDepartment.name || this.state.selectedCampaignCode.name } onClick={this.openDiseaseModal} />
            <img src={this.props.config.assetHost + deleteIcon} width="20" className={`p-hospitals-search__search-box-input-text-delete${this.state.selectedDisease.name || this.state.selectedDepartment.name || this.state.selectedCampaignCode.name ? ' is-active' : ''}`} alt="" onClick={this.clearSearchFiltersAndSelections} />
          </div>
        </div>
        <div className="p-hospitals-search__search-box-input-text-wrap">
          <label className="c-form-label-responsive">病院名</label>
          <div className="p-hospitals-search__search-box-input-text-inner">
            <input className="c-input-text-responsive" name="keyword" id="keyword" type="text" placeholder="医療機関名を入力" value={this.state.keyword} onInput={this.handleKeywordInput} />
          </div>
        </div>
      </>
    );
  }

  render () {
    let rootClassName = '';
    switch (this.props.position) {
    case 'top':
      rootClassName = 'p-hospitals-search__search-box-wrap-mv';
      break;
    case 'bottom':
      rootClassName = 'p-hospitals-search__search-box02-wrap';
      break;
    case 'side':
      rootClassName = 'p-hospitals-search-result__search-box';
      break;
    default:
      break;
    }

    if (rootClassName === '') return null;

    return (
      <>
        <div className={rootClassName}>
          {
            this.props.position === 'side'
              ? (
                <a href="" className="p-hospitals-search-result__search-box-link js-search-box" onClick={this.jsSearchBox}><img src={this.props.config.assetHost + arrowDownIcon} width="24" className="p-hospitals-search-result__search-box-icon" alt="" /><span className="p-hospitals-search-result__search-box-link-txt"></span></a>
              )
              : null
          }
          <div className="p-hospitals-search__search-box">
            <div className="p-hospitals-search__search-box-form">
              <div className="p-hospitals-search__search-box-input-radio-wrap">
                <div className="p-hospitals-search__search-box-subject">
                  対象
                  <div className="p-hospitals-search__search-box-label-tooltip-wrap">
                    <div ref={this.tooltipRef} className={`p-hospitals-search__search-box-label-tooltip ${this.state.isActive ? 'is-active' : ''}`}>
                      <div className="p-hospitals-search__search-box-label-tooltip-inner">
                        <p className="p-hospitals-search__search-box-label-tooltip-title">こんなときは「病院」</p>
                        <p className="p-hospitals-search__search-box-label-tooltip-txt">重症化する可能性がある病気や専門的な治療が必要な場合は、病院を受診してください。</p>
                        <p className="p-hospitals-search__search-box-label-tooltip-title">こんなときは「クリニック」</p>
                        <p className="p-hospitals-search__search-box-label-tooltip-txt">軽い症状の病気や怪我の場合、まずはクリニックで受診してください。</p>
                      </div>
                    </div>
                    <div className="p-hospitals-search__search-box-label-tooltip-icon js-tooltip-show">
                      <img src={this.props.config.assetHost + questionIcon} width="24" className="p-hospitals-search__search-box-subject-icon" alt="" />
                      <div className={'p-hospitals-search__search-box-label-tooltip-icon-click ' + (this.state.isActive ? 'is-open' : '')} onClick={this.tooltipOpen}></div>
                    </div>
                  </div>
                </div>
                <div className="c-radio-btn-responsive__wrap c-radio-btn-responsive__wrap-col3">
                  <div className="c-radio-btn-responsive">
                    <input className="c-radio-btn-responsive__radio" name={`radio_hospital_${this.props.position}`} id={`radio_hospital_${this.props.position}`} type="radio" checked={this.state.selectedType === 'hospital'} value="hospital" onChange={this.handleCheckType}/>
                    <label className="c-radio-btn-responsive__label" htmlFor={`radio_hospital_${this.props.position}`}>病院</label>
                  </div>
                  <div className="c-radio-btn-responsive">
                    <input className="c-radio-btn-responsive__radio" name={`radio_clinic_${this.props.position}`} id={`radio_clinic_${this.props.position}`} type="radio" checked={this.state.selectedType === 'clinic'} value="clinic" onChange={this.handleCheckType} />
                    <label className="c-radio-btn-responsive__label" htmlFor={`radio_clinic_${this.props.position}`}>クリニック</label>
                  </div>
                  <div className="c-radio-btn-responsive">
                    <input className="c-radio-btn-responsive__radio" name={`radio_dental_${this.props.position}`} id={`radio_dental_${this.props.position}`} type="radio" checked={this.state.selectedType === 'dental'} value="dental" onChange={this.handleCheckType} />
                    <label className="c-radio-btn-responsive__label" htmlFor={`radio_dental_${this.props.position}`}>歯科医院</label>
                  </div>
                </div>
              </div>
              {
                this.props.position === 'top'
                  ? this.searchTextForm()
                  : (
                    <div className="p-hospitals-search__search-box-input-wrap">
                      {this.searchTextForm()}
                    </div>
                  )
              }
              <div className="p-hospitals-search__search-box-input-btn-wrap">
                <button className="c-btn-responsive--primary" onClick={this.onClickSearch}>
                  <img src={this.props.config.assetHost + searchWhite} width="32" className="p-hospitals-search__card-icon" alt="" />
                  <span>{this.props.position === 'side' ? '条件を変更する' : '検索する'}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={`p-hospitals-search__modal-bg${this.state.isDiseaseModalActive || this.state.isAreaModalActive ? ' is-open' : ''}`}>
          <ModalArea
            isActive={this.state.isAreaModalActive}
            isCurretLocationLoading={this.state.isCurretLocationLoading}
            handleSelectPrefecture={this.handleSelectPrefecture}
            handleSelectEntirePrefecture={this.handleEntirePrefecture}
            handleSelectCity={this.handleSelectCity}
            handleSelectStation={this.handleSelectStation}
            prefectureData={this.props.prefectureData}
            setCurrentLocation={this.setCurrentLocation}
            changeCurrentLocationLoading={this.changeCurrentLocationLoading}
            selectedPrefecture={this.state.selectedPrefecture}
            selectedCity={this.state.selectedCity}
            selectedStation={this.state.selectedStation}
            closeModal={this.closeModal}
            mixerHost={this.props.mixerHost}
            carbonHost={this.props.carbonHost}
            config={this.props.config}
          />
          <ModalDiseases
            isActive={this.state.isDiseaseModalActive}
            handleSelectDisease={this.handleSelectDisease}
            handleSelectDepartment={this.handleSelectDepartment}
            closeModal={this.closeModal}
            departmentSection={this.props.departmentSection}
            campaignCodesToDisplay={this.props.campaignCodesToDisplay}
            handleSelectCampaignCode={this.handleSelectCampaignCode}
            config={this.props.config}
          />
        </div>
        <FullScreenSpinner active={this.state.isSearchLoading} />
      </>
    );
  }
}

export default SearchBox;
