import React from 'react';
import PropTypes from 'prop-types';
import AbstractInput from './AbstractInput';

export default class SiliconSelect extends AbstractInput {
  constructor (props) {
    super(props);
    this.state = {
      value: props.value || ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange (event) {
    const value = event.target.value;
    this.setState({ value: value });
    this.props.handleChange?.(value);
  }

  render () {
    return (
      <div className="select">
        <select
          id={this.props.id}
          name={this.props.name}
          value={this.state.value}
          onChange={this.handleChange}
        >
          {this.props.options.map((option, i) => {
            return (
              <option key={i} value={option.value}>{option.label}</option>
            );
          })}
        </select>
      </div>
    );
  }
}

SiliconSelect.propTypes = {
  ...AbstractInput.propTypes,
  options: PropTypes.array.isRequired,
  handleChange: PropTypes.func
};

SiliconSelect.defaultProps = {
};
