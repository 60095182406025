import React from 'react';
import {
  AbstractComponent,
  BulmaInputText,
  BulmaSelect,
  RequiredTag,
  Thumbnail
} from '../atoms/index';

export default class ArticleImageForm extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      values: { // アーティクル関連画像属性
        copyright_type: props.articleImage.copyright_type || '',
        author_name: props.articleImage.author_name || '',
        source_url: props.articleImage.source_url || '',
        source_name: props.articleImage.source_name || '',
        source_memo: props.articleImage.source_memo || '',
        memo: props.articleImage.memo || ''
      },
      errors: { // アーティクル関連画像エラー
        copyright_type: '',
        author_name: '',
        source_url: '',
        source_name: '',
        source_memo: '',
        memo: ''
      },
      touched: { // アーティクル関連画像フォーム要素にtouchしたかフラグ
        copyright_type: false,
        author_name: false,
        source_url: false,
        source_name: false,
        source_memo: false,
        memo: false
      },
      submitDisabled: false // // アーティクル関連画像フォームをsubmit出来るかフラグ
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.update = this.update.bind(this);
    this.destroy = this.destroy.bind(this);
    this.renderLinkedArticles = this.renderLinkedArticles.bind(this);
    this.linkedArticlesPresent = this.linkedArticlesPresent.bind(this);
  }

  handleChange (event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState(state => {
      state.values[name] = value;
      const errors = this.validateAll(state.values);
      return { values: state.values, errors: errors, submitDisabled: this.isSubmitDisabled(errors) };
    });
  }

  handleBlur (event) {
    const name = event.target.name;
    this.setState(state => {
      state.touched[name] = true;
      const errors = this.validateAll(state.values);
      return { touched: state.touched, errors: errors, submitDisabled: this.isSubmitDisabled(errors) };
    });
  }

  validateAll (values) {
    const errors = {};
    for (const name of Object.keys(values)) {
      errors[name] = this.validate(values, name);
    }
    return errors;
  }

  validate (values, name) {
    const value = values[name];
    if (name === 'copyright_type') return validateCopyrightType(value);
    if (name === 'author_name') return validateAuthorName(values, value);
    if (name === 'source_url') return validateSourceUrl(value);
    if (name === 'source_name') return validateSourceName(values, value);
    return '';

    function validateCopyrightType (value) {
      if (value !== '') return '';

      return '著作出典種別を選択して下さい';
    }

    function validateAuthorName (values, value) {
      if (values.copyright_type !== 'mn_product') return '';
      if (value !== '') return '';

      return '製作者情報を選択して下さい';
    }

    function validateSourceUrl (value) {
      if (value === '') return '';
      if (/https?:\/\//.test(value) === true) return '';

      return '出典URLを正しく入力して下さい';
    }

    function validateSourceName (values, value) {
      if (values.copyright_type !== 'outsider') return '';
      if (value !== '') return '';

      return '出典元氏名情報を選択して下さい';
    }
  }

  isSubmitDisabled (errors) {
    return Object.values(errors).some(error => error !== '');
  }

  update (event) {
    event.preventDefault();
    return new Promise((resolve, reject) => {
      this
        .fetch(this.props.apiLocation.update, {
          methods: 'PATCH',
          body: this.state.values
        }).then(() => {
          location.href = this.props.apiLocation.index;
          resolve(this.state.values);
        }).catch(() => {
          reject(this.state.values);
        });
    });
  }

  destroy (event) {
    event.preventDefault();
    const result = window.confirm('本当に削除しますか？');
    if (result === true) {
      return new Promise((resolve, reject) => {
        this
          .fetch(this.props.apiLocation.destroy, {
            methods: 'DELETE'
          }).then(() => {
            location.href = this.props.apiLocation.index + '?from=deleted';
            resolve(this.state.values);
          }).catch((error) => {
            reject(new Error(`画像（id:${this.props.articleImage.id}）削除失敗 error: ${error}`));
          });
      });
    }
    return null;
  }

  linkedArticlesPresent () {
    return this.props.linkedArticles.length > 0;
  }

  renderLinkedArticles () {
    if (this.linkedArticlesPresent()) {
      return (
        <>
          <div className="column">
            <p className="subtitle is-5">画像が紐付いているアーティクル一覧</p>
          </div>
          <div className="column">
            <table className="table is-hoverable">
              <thead>
                <tr>
                  <th>id</th>
                  <th>code</th>
                  <th>タイトル</th>
                </tr>
              </thead>
              <tbody>
                {this.props.linkedArticles.map(article => {
                  return (
                    <tr key={article.id}>
                      <td>{article.id}</td>
                      <td>{article.code}</td>
                      <td><a href={`/macaron/articles/${article.id}/edit`} target="_blank" rel="noopener noreferrer">{article.title}</a></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="content is-normal">
            <p>※画像が紐付けられているアーティクルがある場合は削除はできません。</p>
          </div>
        </>
      );
    }
    return null;
  }

  render () {
    return (
      <form>
        <div className="is-flex">
          <div className="mx-6">
            <Thumbnail src={this.props.thumbnail} />
          </div>
          <div className="is-flex-grow-2">
            <div className="field is-horizontal mb-2">
              <div className="field-label" style={{ flexGrow: 1.5 }}>
                <div className="is-flex is-justify-content-flex-end">
                  <label className="label">著作出典種別</label>
                  <div className="ml-2"><RequiredTag /></div>
                </div>
              </div>
              <div className="field-body">
                <div className="field">
                  <BulmaSelect
                    name="copyright_type"
                    value={this.state.values.copyright_type}
                    options={[
                      { label: '---', value: '' },
                      { label: 'MN作成(製作)', value: 'mn_product' },
                      { label: 'MN撮影', value: 'mn_photograph' },
                      { label: '引用', value: 'quote' },
                      { label: '外部提供', value: 'outsider' },
                      { label: 'PIXTA', value: 'pixta' },
                      { label: 'ペイレス', value: 'payless' },
                      { label: '他素材サイト', value: 'other_material_site' },
                      { label: '不明', value: 'unknown' }
                    ]}
                    hasErrors={this.state.touched.copyright_type === true && this.state.errors.copyright_type !== ''}
                    handleChange={this.handleChange}
                    handleBlur={this.handleBlur}
                  />
                  {this.state.touched.copyright_type === true && this.state.errors.copyright_type !== ''
                    ? <p className="help is-danger">{this.state.errors.copyright_type}</p>
                    : null}
                </div>
              </div>
            </div>
            <div className="field is-horizontal mb-2">
              <div className="field-label" style={{ flexGrow: 1.5 }}>
                <div className="is-flex is-justify-content-flex-end">
                  <label className="label">製作者情報</label>
                  {this.state.values.copyright_type === 'mn_product' ? <div className="ml-2"><RequiredTag /></div> : null}
                </div>
              </div>
              <div className="field-body">
                <div className="field">
                  <BulmaInputText
                    name="author_name"
                    value={this.state.values.author_name}
                    hasErrors={this.state.touched.author_name === true && this.state.errors.author_name !== ''}
                    handleChange={this.handleChange}
                    handleBlur={this.handleBlur}
                  />
                  {this.state.touched.author_name === true && this.state.errors.author_name !== ''
                    ? <p className="help is-danger">{this.state.errors.author_name}</p>
                    : null}
                </div>
              </div>
            </div>
            <div className="field is-horizontal mb-2">
              <div className="field-label" style={{ flexGrow: 1.5 }}>
                <div className="is-flex is-justify-content-flex-end">
                  <label className="label">出典URL</label>
                </div>
              </div>
              <div className="field-body">
                <div className="field">
                  <BulmaInputText
                    name="source_url"
                    value={this.state.values.source_url}
                    hasErrors={this.state.touched.source_url === true && this.state.errors.source_url !== ''}
                    handleChange={this.handleChange}
                    handleBlur={this.handleBlur}
                  />
                  {this.state.touched.source_url === true && this.state.errors.source_url !== ''
                    ? <p className="help is-danger">{this.state.errors.source_url}</p>
                    : null}
                </div>
              </div>
            </div>
            <div className="field is-horizontal mb-2">
              <div className="field-label" style={{ flexGrow: 1.5 }}>
                <div className="is-flex is-justify-content-flex-end">
                  <label className="label">出典元氏名情報</label>
                  {this.state.values.copyright_type === 'outsider' ? <div className="ml-2"><RequiredTag /></div> : null}
                </div>
              </div>
              <div className="field-body">
                <div className="field">
                  <BulmaInputText
                    name="source_name"
                    value={this.state.values.source_name}
                    hasErrors={this.state.touched.source_name === true && this.state.errors.source_name !== ''}
                    handleChange={this.handleChange}
                    handleBlur={this.handleBlur}
                  />
                  {this.state.touched.source_name === true && this.state.errors.source_name !== ''
                    ? <p className="help is-danger">{this.state.errors.source_name}</p>
                    : null}
                </div>
              </div>
            </div>
            <div className="field is-horizontal mb-2">
              <div className="field-label" style={{ flexGrow: 1.5 }}>
                <div className="is-flex is-justify-content-flex-end">
                  <label className="label">出典元所属元情報</label>
                </div>
              </div>
              <div className="field-body">
                <div className="field">
                  <BulmaInputText
                    name="source_memo"
                    value={this.state.values.source_memo}
                    hasErrors={this.state.touched.source_memo === true && this.state.errors.source_memo !== ''}
                    handleChange={this.handleChange}
                    handleBlur={this.handleBlur}
                  />
                  {this.state.touched.source_memo === true && this.state.errors.source_memo !== ''
                    ? <p className="help is-danger">{this.state.errors.source_memo}</p>
                    : null}
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label" style={{ flexGrow: 1.5 }}>
                <div className="is-flex is-justify-content-flex-end">
                  <label className="label">補足情報</label>
                </div>
              </div>
              <div className="field-body">
                <div className="field">
                  <BulmaInputText
                    name="memo"
                    value={this.state.values.memo}
                    hasErrors={this.state.touched.memo === true && this.state.errors.memo !== ''}
                    handleChange={this.handleChange}
                    handleBlur={this.handleBlur}
                  />
                  {this.state.touched.memo === true && this.state.errors.memo !== ''
                    ? <p className="help is-danger">{this.state.errors.memo}</p>
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderLinkedArticles()}
        <div className="field is-grouped is-pulled-right mt-4">
          <div className="control has-text-center">
            <button type="button" className="button is-link" onClick={this.update} disabled={this.state.submitDisabled}>保存</button>
          </div>
          <div className="control has-text-center">
            <button type="button" className="button is-danger" onClick={this.destroy} disabled={this.linkedArticlesPresent()}>削除</button>
          </div>
        </div>
      </form>
    );
  }
}
