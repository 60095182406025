import React from 'react';
import PropTypes from 'prop-types';
import SiliconSelect from '../atoms/SiliconSelect';
import MacaronAsyncSelect from '../atoms/MacaronAsyncSelect';

export default class ArticleMedicalInvolvingInfo extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      medicalInvolvingInfoId: this.props.id,
      importanceScore: this.props.importance_score
    };
  }

  render () {
    return (
      <>
        <input type="hidden" value={this.state.medicalInvolvingInfoId} name={`article[article_medical_involving_infos_attributes][${this.props.i}][id]`} id={`article_article_medical_involving_infos_attributes_${this.props.i}_id`} />
        <input type="hidden" value={this.props.destroy} name={`article[article_medical_involving_infos_attributes][${this.props.i}][_destroy]`} id={`article_article_medical_involving_infos_attributes_${this.props.i}__destroy`} />
        <div className="is-flex-direction-column mb-4">
          <div className="field is-horizontal mb-4">
            <div className="field-label is-normal" style={{ whiteSpace: 'nowrap' }}>
              <label>関連種別</label>
            </div>
            <div className="field-body">
              <div className="field is-narrow">
                <SiliconSelect
                  id={`article_article_medical_involving_infos_attributes_${this.props.i}_involving_type`}
                  name={`article[article_medical_involving_infos_attributes][${this.props.i}][involving_type]`}
                  options={this.props.medicalInvolvingTypeOptions}
                  value={this.props.involving_type}
                />
              </div>
            </div>
            <div className="field-label is-normal" style={{ whiteSpace: 'nowrap' }}>
              <label>重要度(数字が大きいほど重要)</label>
            </div>
            <div className="field-body">
              <div className="field is-narrow">
                <input
                  type="number"
                  id={`article_article_medical_involving_infos_attributes_${this.props.i}_importance_score`}
                  name={`article[article_medical_involving_infos_attributes][${this.props.i}][importance_score]`}
                  defaultValue={this.state.importanceScore}
                  className="input"
                  min="0"
                />
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal" style={{ whiteSpace: 'nowrap' }}>
              <label>医療関係者名</label>
            </div>
            <div className="field-body">
              <div className="field is-narrow" style={{ width: '100%' }}>
                <MacaronAsyncSelect
                  value={this.props.selected_medical}
                  suggestUrl='/macaron/api/suggest/doctors'
                  suggestUrlQueryKey='name'
                  hiddenParamName={`article[article_medical_involving_infos_attributes][${this.props.i}][candy_doctor_code]`}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ArticleMedicalInvolvingInfo.propTypes = {
  destroy: PropTypes.bool,
  id: PropTypes.number,
  i: PropTypes.number,
  medicalInvolvingTypeOptions: PropTypes.array,
  selected_medical: PropTypes.object,
  importance_score: PropTypes.number,
  involving_type: PropTypes.string
};

ArticleMedicalInvolvingInfo.defaultProps = {
};
