import React from 'react';
import PropTypes from 'prop-types';
import SiliconSelect from '../atoms/SiliconSelect';

export default class ArticleGroupSelect extends React.Component {
  render () {
    return (
      <>
        <input type="hidden" value={this.props.id} name={`article[article_related_article_groups_attributes][${this.props.i}][id]`} id={`article_article_related_article_groups_attributes_${this.props.i}_id`}></input>
        <input type="hidden" value={this.props.destroy} name={`article[article_related_article_groups_attributes][${this.props.i}][_destroy]`} id={`article_article_related_article_groups_attributes_${this.props.i}__destroy`}></input>
        <SiliconSelect
          id={`article_article_related_article_groups_attributes_${this.props.i}_article_group_id`}
          name={`article[article_related_article_groups_attributes][${this.props.i}][article_group_id]`}
          options={this.props.options}
          value={this.props.article_group_id}
        />
      </>
    );
  }
}

ArticleGroupSelect.propTypes = {
  id: PropTypes.number,
  i: PropTypes.number.isRequired,
  article_group_id: PropTypes.number,
  destroy: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired
};

ArticleGroupSelect.defaultProps = {
};
