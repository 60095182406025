import React from 'react';
import PropTypes from 'prop-types';
import {
  BulmaModal,
  Thumbnail
} from '../atoms/index';

export default class ArticleImageModal extends React.Component {
  constructor (props) {
    super(props);
    this.state = { articleImageId: '' };
    this.getThumbnailSourceUrlWithImgIxOptions = this.getThumbnailSourceUrlWithImgIxOptions.bind(this);
    this.handleChangeImageId = this.handleChangeImageId.bind(this);
    this.handleClickSearchByImageId = this.handleClickSearchByImageId.bind(this);
  }

  getThumbnailSourceUrlWithImgIxOptions (sourceUrl) {
    const options = sourceUrl.includes('?') ? '&w=128&h=128' : '?w=128&h=128';
    return sourceUrl + options;
  }

  handleChangeImageId (event) {
    this.setState({ articleImageId: event.target.value });
  }

  handleClickSearchByImageId () {
    this.props.searchByImageId(this.state.articleImageId);
  }

  render () {
    return (
      <BulmaModal
        isActive={this.props.isActive}
        handleClose={this.props.handleClose}
        modalStyle={{ width: '910px' }}
      >
        <div style={{ height: '600px', overflowY: 'scroll' }}>

          <div className="columns ml-2">
            <div className="field column is-6">
              <div className="control is-fullwidth">
                <input
                  className="input"
                  type="text"
                  placeholder="画像IDを入力"
                  value={this.state.articleImageId}
                  onChange={this.handleChangeImageId}
                />
              </div>
            </div>
            <div className="field column is-6">
              <div className="control">
                <button
                  type="button"
                  className="button is-primary"
                  onClick={this.handleClickSearchByImageId}
                >
                 検索
                </button>
              </div>
            </div>
          </div>

          <ul className="is-flex is-flex-wrap-wrap ml-2 mb-2" style={{ listStyleType: 'none' }}>
            {this.props.images.map(image => {
              return (
                <li
                  key={image.id}
                  className="mb-4 ml-4"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.props.handleSelected(image)}
                >
                  <span>{ image.id }</span>
                  <Thumbnail src={this.getThumbnailSourceUrlWithImgIxOptions(image.thumbnail_src)} />
                </li>
              );
            })}
          </ul>
          { this.props.images.length === 0 && <p>画像がありません</p> }
        </div>
      </BulmaModal>
    );
  }
}

ArticleImageModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  images: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSelected: PropTypes.func.isRequired,
  searchByImageId: PropTypes.func
};

ArticleImageModal.defaultProps = {
  images: []
};
