import React from 'react';
import PropTypes from 'prop-types';

export default function BulmaRadio (props) {
  return (
    <>
      {props.radios.map((radio, i) => {
        return (
          <label key={i} className="radio">
            <input
              name={props.name}
              value={radio.value}
              type="radio"
              checked={props.value === radio.value}
              onChange={event => props.handleChange(event.target.value)}
            />
            {radio.label}
          </label>
        );
      })}
    </>
  );
}

BulmaRadio.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  radios: PropTypes.array
};

BulmaRadio.defaultProps = {
  id: '',
  name: '',
  value: '',
  handleChange: (value) => console.log(value),
  radios: []
};
