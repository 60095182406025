import React from 'react';
import PropTypes from 'prop-types';
import { BulmaImage } from './index';

export default class Thumbnail extends React.Component {
  render () {
    return (
      <BulmaImage
        src={this.props.src}
        additionalClassName="is-128x128 is-flex is-justify-content-center is-align-items-center p-2"
        figureStyle={{ border: '1px solid grey' }}
        imgStyle={{ width: '128px', height: '128px', 'object-fit': 'contain' }}
      />
    );
  }
}

Thumbnail.propTypes = {
  src: PropTypes.string
};
