import React from 'react';
import {
  AbstractComponent,
  ArticleImageInputFile,
  BulmaInputText,
  BulmaModal,
  BulmaSelect,
  RequiredTag,
  Thumbnail
} from '../atoms/index';

export default class ArticleImageUploader extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      isActive: false, // アーティクル関連画像モーダルの開閉フラグ
      files: [], // アップロードした画像
      uploadErrors: [], // アップロード時のバリデーションエラー配列
      values: [], // アーティクル関連画像属性
      errors: [], // アーティクル関連画像エラー
      touched: [], // アーティクル関連画像フォーム要素にtouchしたかフラグ
      submitDisabled: true // // アーティクル関連画像フォームをsubmit出来るかフラグ
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.pushFile = this.pushFile.bind(this);
    this.pushUploadErrors = this.pushUploadErrors.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.update = this.update.bind(this);
  }

  openModal () {
    this.setState({ isActive: true });
  }

  closeModal () {
    this.setState({ isActive: false, files: [], uploadErrors: [], values: [], errors: [], touched: [] });
  }

  pushFile (file) {
    this.setState(state => {
      state.files.push(file);
      state.values.push(this.initValue(file));
      state.errors.push(this.initError());
      state.touched.push(this.initTouched());
      return { files: state.files, values: state.values, errors: state.errors, touched: state.touched };
    });
  }

  pushUploadErrors (error) {
    this.setState(state => {
      state.uploadErrors.push(error);
      return { errors: state.uploadErrors };
    });
  }

  initValue (file) {
    return {
      api_location: file.api_location,
      copyright_type: '',
      author_name: '',
      source_url: '',
      source_name: '',
      source_memo: '',
      memo: ''
    };
  }

  initError () {
    return {
      copyright_type: '',
      author_name: '',
      source_url: '',
      source_name: '',
      source_memo: '',
      memo: ''
    };
  }

  initTouched () {
    return {
      copyright_type: false,
      author_name: false,
      source_url: false,
      source_name: false,
      source_memo: false,
      memo: false
    };
  }

  handleChange (event) {
    const name = event.target.name;
    const value = event.target.value;
    const index = event.target.dataset.index;
    this.setState(state => {
      state.values[index][name] = value;
      const errors = this.validateAll(state.values);
      return { values: state.values, errors: errors, submitDisabled: this.isSubmitDisabled(errors) };
    });
  }

  handleBlur (event) {
    const name = event.target.name;
    const index = event.target.dataset.index;
    this.setState(state => {
      state.touched[index][name] = true;
      const errors = this.validateAll(state.values);
      return { touched: state.touched, errors: errors, submitDisabled: this.isSubmitDisabled(errors) };
    });
  }

  validateAll (valuesAll) {
    return valuesAll.map(values => {
      const errors = {};
      for (const [name] of Object.entries(values)) {
        if (name === 'api_location') {
          continue;
        }
        errors[name] = this.validate(values, name);
      }
      return errors;
    });
  }

  validate (values, name) {
    const value = values[name];
    let error = '';
    switch (name) {
    case 'copyright_type':
      if (value === '') {
        error = '著作出典種別を選択して下さい';
      }
      break;
    case 'author_name':
      if (values.copyright_type === 'mn_product' && value === '') {
        error = '製作者情報を選択して下さい';
      }
      break;
    case 'source_url':
      if (value !== '' && /https?:\/\//.test(value) !== true) {
        error = '出典URLを正しく入力して下さい';
      }
      break;
    case 'source_name':
      if (values.copyright_type === 'outsider' && value === '') {
        error = '出典元氏名情報を選択して下さい';
      }
      break;
    case 'source_memo':
      break;
    case 'memo':
      break;
    }
    return error;
  }

  isSubmitDisabled (errors) {
    const result = errors.every(error => {
      return Object.values(error).every(value => value === '');
    });
    return !result;
  }

  update (event) {
    event.preventDefault();
    return new Promise((resolve, reject) => {
      this.state.values.forEach(values => {
        const apiLocation = values.api_location;
        delete values.api_location;
        this
          .fetch(apiLocation, {
            methods: 'PATCH',
            body: values
          }).then(() => {
            resolve(values);
          }).catch(() => {
            reject(values);
          });
      });
    })
      .then(() => {
        this.closeModal();
      });
  }

  renderUploadErrors (errors) {
    return (
      <ul className="errors mb-4">
        {errors.map((error, index) => (
          <li key={index} className="">
            <i className="fas fa-exclamation-circle has-text-danger mr-2"></i>
            <span className="has-text-danger">{error}</span>
          </li>
        ))}
      </ul>
    );
  }

  render () {
    return (
      <>
        <div className="file">
          <label className="file-label">
            <ArticleImageInputFile
              name="image"
              className="file-input"
              apiLocation={this.props.apiLocation}
              openModal={this.openModal}
              pushFile={this.pushFile}
              pushUploadErrors={this.pushUploadErrors}
            />
            <span className="file-cta">
              <span className="file-icon">
                <i className="fas fa-upload"></i>
              </span>
              <span className="file-label">画像を投稿</span>
            </span>
          </label>
        </div>
        <BulmaModal isActive={this.state.isActive} handleClose={this.closeModal}>
          {this.state.uploadErrors.length === 0 && this.state.files.length > 0 &&
          <div className="mb-5">
            <article className="message is-success">
              <div className="message-header">
                <p>画像をアップロードしました</p>
              </div>
              <div className="message-body">
                <p>必要情報を入力してください</p>
                <p>必要情報を入力しない場合は画像を使用できません</p>
                <p>必要情報は画像一覧より後からでも編集可能です</p>
              </div>
            </article>
          </div>
          }
          <form>
            <ul className="mb-2">
              {this.state.files.map((file, index) => (
                <li key={index} className="mb-4 is-flex">
                  <Thumbnail src={file.src} />
                  <div className="is-flex-grow-2">
                    <div className="field is-horizontal mb-2">
                      <div className="field-label is-flex-grow-3">
                        <div className="is-flex is-justify-content-flex-end">
                          <label className="label">著作出典種別</label>
                          <div className="ml-2"><RequiredTag /></div>
                        </div>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <BulmaSelect
                            name="copyright_type"
                            value={this.state.values[index].copyright_type}
                            index={index}
                            options={[
                              { label: '---', value: '' },
                              { label: 'MN作成(製作)', value: 'mn_product' },
                              { label: 'MN撮影', value: 'mn_photograph' },
                              { label: '引用', value: 'quote' },
                              { label: '外部提供', value: 'outsider' },
                              { label: 'PIXTA', value: 'pixta' },
                              { label: 'ペイレス', value: 'payless' },
                              { label: '他素材サイト', value: 'other_material_site' },
                              { label: '不明', value: 'unknown' }
                            ]}
                            hasErrors={this.state.touched[index].copyright_type === true && this.state.errors[index].copyright_type !== ''}
                            handleChange={this.handleChange}
                            handleBlur={this.handleBlur}
                          />
                          {this.state.touched[index].copyright_type === true && this.state.errors[index].copyright_type !== ''
                            ? <p className="help is-danger">{this.state.errors[index].copyright_type}</p>
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="field is-horizontal mb-2">
                      <div className="field-label is-flex-grow-3">
                        <div className="is-flex is-justify-content-flex-end">
                          <label className="label">製作者情報</label>
                          {this.state.values[index]?.copyright_type === 'mn_product' ? <div className="ml-2"><RequiredTag /></div> : null}
                        </div>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <BulmaInputText
                            name="author_name"
                            value={this.state.values[index].author_name}
                            index={index}
                            hasErrors={this.state.touched[index].author_name === true && this.state.errors[index].author_name !== ''}
                            handleChange={this.handleChange}
                            handleBlur={this.handleBlur}
                          />
                          {this.state.touched[index].author_name === true && this.state.errors[index].author_name !== ''
                            ? <p className="help is-danger">{this.state.errors[index].author_name}</p>
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="field is-horizontal mb-2">
                      <div className="field-label is-flex-grow-3">
                        <div className="is-flex is-justify-content-flex-end">
                          <label className="label">出典URL</label>
                        </div>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <BulmaInputText
                            name="source_url"
                            value={this.state.values[index].source_url}
                            index={index}
                            hasErrors={this.state.touched[index].source_url === true && this.state.errors[index].source_url !== ''}
                            handleChange={this.handleChange}
                            handleBlur={this.handleBlur}
                          />
                          {this.state.touched[index].source_url === true && this.state.errors[index].source_url !== ''
                            ? <p className="help is-danger">{this.state.errors[index].source_url}</p>
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="field is-horizontal mb-2">
                      <div className="field-label is-flex-grow-3">
                        <div className="is-flex is-justify-content-flex-end">
                          <label className="label">出典元氏名情報</label>
                          {this.state.values[index]?.copyright_type === 'outsider' ? <div className="ml-2"><RequiredTag /></div> : null}
                        </div>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <BulmaInputText
                            name="source_name"
                            value={this.state.values[index].source_name}
                            index={index}
                            hasErrors={this.state.touched[index].source_name === true && this.state.errors[index].source_name !== ''}
                            handleChange={this.handleChange}
                            handleBlur={this.handleBlur}
                          />
                          {this.state.touched[index].source_name === true && this.state.errors[index].source_name !== ''
                            ? <p className="help is-danger">{this.state.errors[index].source_name}</p>
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="field is-horizontal mb-2">
                      <div className="field-label is-flex-grow-3">
                        <div className="is-flex is-justify-content-flex-end">
                          <label className="label">出典元所属元情報</label>
                        </div>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <BulmaInputText
                            name="source_memo"
                            value={this.state.values[index].source_memo}
                            index={index}
                            hasErrors={this.state.touched[index].source_memo === true && this.state.errors[index].source_memo !== ''}
                            handleChange={this.handleChange}
                            handleBlur={this.handleBlur}
                          />
                          {this.state.touched[index].source_memo === true && this.state.errors[index].source_memo !== ''
                            ? <p className="help is-danger">{this.state.errors[index].source_memo}</p>
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="field is-horizontal">
                      <div className="field-label is-flex-grow-3">
                        <div className="is-flex is-justify-content-flex-end">
                          <label className="label">補足情報</label>
                        </div>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <BulmaInputText
                            name="memo"
                            value={this.state.values[index].memo}
                            index={index}
                            hasErrors={this.state.touched[index].memo === true && this.state.errors[index].memo !== ''}
                            handleChange={this.handleChange}
                            handleBlur={this.handleBlur}
                          />
                          {this.state.touched[index].memo === true && this.state.errors[index].memo !== ''
                            ? <p className="help is-danger">{this.state.errors[index].memo}</p>
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {this.state.uploadErrors.length > 0 ? this.renderUploadErrors(this.state.uploadErrors) : null}
            <div className="field">
              <div className="control">
                <button
                  className="button is-link"
                  onClick={this.update}
                  disabled={this.state.submitDisabled}
                >
                  送信
                </button>
              </div>
            </div>
          </form>
        </BulmaModal>
      </>
    );
  }
}
