import React from 'react';
import PropTypes from 'prop-types';

export const HeaderMenu = (props) => {
  const isActive = props.isActive;
  const isLoggedIn = props.isLoggedIn;

  return (
    <div className={'l-header-menu ' + (isActive ? 'is-open' : '')}>
      <div className="p-header-menu__contents">
        {!isLoggedIn &&
          <div className="p-header-menu__login">
            <p className="p-header-menu__login-lead">会員登録で、役立つ病気に関する情報などをお知らせします</p>
            <ul>
              <li><a className="c-mn-btn--first" href="/forward_to_signup"><span>新規会員登録する</span><span className="p-header-menu__login-free">無料</span></a></li>
              <li><a className="c-mn-btn--second" href="/forward_to_login"><span>ログインする</span></a></li>
            </ul>
          </div>
        }
        <div className="p-header-menu__list">
          <h2 className="p-header-menu__list-title">病気や症状を調べる</h2>
          <ul>
            <li><a href="/diseases"><span>病気を調べる</span><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.99573 7.00042C8.02108 6.70575 7.921 6.40233 7.6955 6.17683L1.82275 0.304072C1.41732 -0.101357 0.759988 -0.101357 0.354559 0.304072C-0.0508701 0.709501 -0.0508693 1.36683 0.35456 1.77226L5.58226 6.99996L0.354487 12.2277C-0.0509423 12.6332 -0.0509421 13.2905 0.354487 13.6959C0.759916 14.1014 1.41725 14.1014 1.82268 13.6959L7.69543 7.82317C7.92072 7.59789 8.02082 7.29483 7.99573 7.00042Z" fill="#27B1DB"></path></svg></a></li>
            <li><a href="/symptoms"><span>症状を調べる</span><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.99573 7.00042C8.02108 6.70575 7.921 6.40233 7.6955 6.17683L1.82275 0.304072C1.41732 -0.101357 0.759988 -0.101357 0.354559 0.304072C-0.0508701 0.709501 -0.0508693 1.36683 0.35456 1.77226L5.58226 6.99996L0.354487 12.2277C-0.0509423 12.6332 -0.0509421 13.2905 0.354487 13.6959C0.759916 14.1014 1.41725 14.1014 1.82268 13.6959L7.69543 7.82317C7.92072 7.59789 8.02082 7.29483 7.99573 7.00042Z" fill="#27B1DB"></path></svg></a></li>
            <li><a href="/checkups"><span>検査名を調べる</span><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.99573 7.00042C8.02108 6.70575 7.921 6.40233 7.6955 6.17683L1.82275 0.304072C1.41732 -0.101357 0.759988 -0.101357 0.354559 0.304072C-0.0508701 0.709501 -0.0508693 1.36683 0.35456 1.77226L5.58226 6.99996L0.354487 12.2277C-0.0509423 12.6332 -0.0509421 13.2905 0.354487 13.6959C0.759916 14.1014 1.41725 14.1014 1.82268 13.6959L7.69543 7.82317C7.92072 7.59789 8.02082 7.29483 7.99573 7.00042Z" fill="#27B1DB"></path></svg></a></li>
          </ul>
        </div>
        <div className="p-header-menu__list">
          <h2 className="p-header-menu__list-title"><a href="https://medicalnote-qa.jp">医療相談<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0Z" fill="white"></path><path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 4.5 4.5 0 10 0C15.5 0 20 4.5 20 10C20 15.5 15.5 20 10 20C4.5 20 0 15.5 0 10ZM7.43575 5.99282C7.04523 5.6023 7.04523 4.96913 7.43575 4.57861C7.82627 4.18808 8.45944 4.18808 8.84996 4.57861L13.4967 9.22531C13.5208 9.2494 13.5434 9.27442 13.5645 9.30025C13.5903 9.32136 13.6153 9.34396 13.6394 9.36805C14.0299 9.75858 14.0299 10.3917 13.6394 10.7823L8.993 15.4287C8.60247 15.8192 7.96931 15.8192 7.57878 15.4287C7.18826 15.0381 7.18826 14.405 7.57878 14.0145L11.5181 10.0752L7.43575 5.99282Z" fill="#27B1DB"></path></svg></a></h2>
        </div>
        {/* <div className="p-header-menu__list"> */}
        {/*  <h2 className="p-header-menu__list-title"><a className="GA_click_health_records_hospitals_menu" href="/health_records">治療ノート<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0Z" fill="white"></path><path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 4.5 4.5 0 10 0C15.5 0 20 4.5 20 10C20 15.5 15.5 20 10 20C4.5 20 0 15.5 0 10ZM7.43575 5.99282C7.04523 5.6023 7.04523 4.96913 7.43575 4.57861C7.82627 4.18808 8.45944 4.18808 8.84996 4.57861L13.4967 9.22531C13.5208 9.2494 13.5434 9.27442 13.5645 9.30025C13.5903 9.32136 13.6153 9.34396 13.6394 9.36805C14.0299 9.75858 14.0299 10.3917 13.6394 10.7823L8.993 15.4287C8.60247 15.8192 7.96931 15.8192 7.57878 15.4287C7.18826 15.0381 7.18826 14.405 7.57878 14.0145L11.5181 10.0752L7.43575 5.99282Z" fill="#27B1DB"></path></svg></a></h2> */}
        {/* </div> */}
        <div className="p-header-menu__list">
          <h2 className="p-header-menu__list-title">医療・病気について知る</h2>
          <ul>
            <li><a href="/nj_articles"><span>NEWS &amp; JOURNAL</span><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.99573 7.00042C8.02108 6.70575 7.921 6.40233 7.6955 6.17683L1.82275 0.304072C1.41732 -0.101357 0.759988 -0.101357 0.354559 0.304072C-0.0508701 0.709501 -0.0508693 1.36683 0.35456 1.77226L5.58226 6.99996L0.354487 12.2277C-0.0509423 12.6332 -0.0509421 13.2905 0.354487 13.6959C0.759916 14.1014 1.41725 14.1014 1.82268 13.6959L7.69543 7.82317C7.92072 7.59789 8.02082 7.29483 7.99573 7.00042Z" fill="#27B1DB"></path></svg></a></li>
            <li><a href="/cancerportal"><span>がんポータル</span><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.99573 7.00042C8.02108 6.70575 7.921 6.40233 7.6955 6.17683L1.82275 0.304072C1.41732 -0.101357 0.759988 -0.101357 0.354559 0.304072C-0.0508701 0.709501 -0.0508693 1.36683 0.35456 1.77226L5.58226 6.99996L0.354487 12.2277C-0.0509423 12.6332 -0.0509421 13.2905 0.354487 13.6959C0.759916 14.1014 1.41725 14.1014 1.82268 13.6959L7.69543 7.82317C7.92072 7.59789 8.02082 7.29483 7.99573 7.00042Z" fill="#27B1DB"></path></svg></a></li>
            <li><a href="/covid19"><span>新型コロナウイルス感染症特設サイト</span><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.99573 7.00042C8.02108 6.70575 7.921 6.40233 7.6955 6.17683L1.82275 0.304072C1.41732 -0.101357 0.759988 -0.101357 0.354559 0.304072C-0.0508701 0.709501 -0.0508693 1.36683 0.35456 1.77226L5.58226 6.99996L0.354487 12.2277C-0.0509423 12.6332 -0.0509421 13.2905 0.354487 13.6959C0.759916 14.1014 1.41725 14.1014 1.82268 13.6959L7.69543 7.82317C7.92072 7.59789 8.02082 7.29483 7.99573 7.00042Z" fill="#27B1DB"></path></svg></a></li>
            <li><a href="/features"><span>特集・ピックアップ</span><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.99573 7.00042C8.02108 6.70575 7.921 6.40233 7.6955 6.17683L1.82275 0.304072C1.41732 -0.101357 0.759988 -0.101357 0.354559 0.304072C-0.0508701 0.709501 -0.0508693 1.36683 0.35456 1.77226L5.58226 6.99996L0.354487 12.2277C-0.0509423 12.6332 -0.0509421 13.2905 0.354487 13.6959C0.759916 14.1014 1.41725 14.1014 1.82268 13.6959L7.69543 7.82317C7.92072 7.59789 8.02082 7.29483 7.99573 7.00042Z" fill="#27B1DB"></path></svg></a></li>
          </ul>
        </div>
        <div className="p-header-menu__list">
          <h2 className="p-header-menu__list-title">受診・治療先を探す</h2>
          <ul>
            <li><a href="/hospitals"><span>病院・クリニックを探す</span><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.99573 7.00042C8.02108 6.70575 7.921 6.40233 7.6955 6.17683L1.82275 0.304072C1.41732 -0.101357 0.759988 -0.101357 0.354559 0.304072C-0.0508701 0.709501 -0.0508693 1.36683 0.35456 1.77226L5.58226 6.99996L0.354487 12.2277C-0.0509423 12.6332 -0.0509421 13.2905 0.354487 13.6959C0.759916 14.1014 1.41725 14.1014 1.82268 13.6959L7.69543 7.82317C7.92072 7.59789 8.02082 7.29483 7.99573 7.00042Z" fill="#27B1DB"></path></svg></a></li>
            <li><a href="/doctors"><span>医師を探す</span><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.99573 7.00042C8.02108 6.70575 7.921 6.40233 7.6955 6.17683L1.82275 0.304072C1.41732 -0.101357 0.759988 -0.101357 0.354559 0.304072C-0.0508701 0.709501 -0.0508693 1.36683 0.35456 1.77226L5.58226 6.99996L0.354487 12.2277C-0.0509423 12.6332 -0.0509421 13.2905 0.354487 13.6959C0.759916 14.1014 1.41725 14.1014 1.82268 13.6959L7.69543 7.82317C7.92072 7.59789 8.02082 7.29483 7.99573 7.00042Z" fill="#27B1DB"></path></svg></a></li>
          </ul>
        </div>
        {isLoggedIn &&
          <div className="p-header-menu__list">
            <h2 className="p-header-menu__list-title">会員ページ</h2>
            <ul>
              <li>
                <a href="/user">
                  <span>マイページ</span>
                  <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.99573 7.00042C8.02108 6.70575 7.921 6.40233 7.6955 6.17683L1.82275 0.304072C1.41732 -0.101357 0.759988 -0.101357 0.354559 0.304072C-0.0508701 0.709501 -0.0508693 1.36683 0.35456 1.77226L5.58226 6.99996L0.354487 12.2277C-0.0509423 12.6332 -0.0509421 13.2905 0.354487 13.6959C0.759916 14.1014 1.41725 14.1014 1.82268 13.6959L7.69543 7.82317C7.92072 7.59789 8.02082 7.29483 7.99573 7.00042Z" fill="#27B1DB" />
                  </svg>
                </a>
              </li>
              <li>
                <a href="/logout" data-method="delete">
                  <span>ログアウト</span>
                  <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.99573 7.00042C8.02108 6.70575 7.921 6.40233 7.6955 6.17683L1.82275 0.304072C1.41732 -0.101357 0.759988 -0.101357 0.354559 0.304072C-0.0508701 0.709501 -0.0508693 1.36683 0.35456 1.77226L5.58226 6.99996L0.354487 12.2277C-0.0509423 12.6332 -0.0509421 13.2905 0.354487 13.6959C0.759916 14.1014 1.41725 14.1014 1.82268 13.6959L7.69543 7.82317C7.92072 7.59789 8.02082 7.29483 7.99573 7.00042Z" fill="#27B1DB" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        }
      </div>
    </div>
  );
};

HeaderMenu.propTypes = {
  isActive: PropTypes.bool.isRequired, // ヘッダーメニューを表示するかどうか
  isLoggedIn: PropTypes.bool.isRequired
};

export default HeaderMenu;
