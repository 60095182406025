import React from 'react';
import {
  AbstractComponent,
  Thumbnail
} from '../atoms/index';
import {
  ArticleImageModal
} from '../molecules/index';

export default class ArticleCoverImages extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      isActive: false, // アーティクル関連画像モーダルの開閉フラグ
      images: [], // アーティクル関連画像
      coverImage: props.coverImage || null // ヘッダ画像
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.search = this.search.bind(this);
    this.searchByImageId = this.searchByImageId.bind(this);
  }

  handleOpen () {
    this.setState({ isActive: true });
  }

  handleClose (event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({ isActive: false });
  }

  search (managedName = null) {
    const apiLocation = managedName ? `${this.props.apiLocation}?managed_name=${managedName}` : this.props.apiLocation;
    this
      .fetch(apiLocation)
      .then(data => {
        this.setState({ images: data.data, isActive: true });
      });
  }

  searchByImageId (articleImageId) {
    const apiLocation = `${this.props.apiLocation}?article_image_id=${articleImageId}`;
    this
      .fetch(apiLocation)
      .then(data => {
        this.setState({ images: data.data });
      });
  }

  renderDestroyButton () {
    if (this.state.coverImage) {
      return (
        <div className="field mb-2">
          <div className="control">
            <button
              className="button is-danger"
              onClick={event => {
                event.preventDefault();
                this.setState({ coverImage: null });
              }}
            >
              削除
            </button>
          </div>
        </div>
      );
    }
    return null;
  }

  render () {
    return (
      <>
        <div className="mb-2">
          <Thumbnail src={this.state.coverImage?.src || this.props.defaultImageSrc} />
          <input type="hidden" name={`article[article_cover_images_attributes][${this.props.nestedFormId}][id]`} value={this.props.coverImage ? this.props.coverImage.id : ''} />
          <input type="hidden" name={`article[article_cover_images_attributes][${this.props.nestedFormId}][article_image_id]`} value={this.state.coverImage ? this.state.coverImage.article_image_id : ''} />
          <input type="hidden" name={`article[article_cover_images_attributes][${this.props.nestedFormId}][device_type]`} value={this.props.deviceType} />
          <input type="hidden" name={`article[article_cover_images_attributes][${this.props.nestedFormId}][_destroy]`} value={this.state.coverImage ? 0 : 1} />
        </div>
        {this.renderDestroyButton()}
        <div className="file">
          <label className="file-label">
            <button
              className="file-input"
              onClick={event => {
                event.preventDefault();
                this.setState({ searchWord: '' });
                this.search();
              }}
            >
            </button>
            <span className="file-cta">
              <span className="file-icon">
                <i className="fas fa-cloud-download-alt"></i>
              </span>
              <span className="file-label">{this.props.deviceType.toUpperCase()}画像選択</span>
            </span>
          </label>
        </div>
        <ArticleImageModal
          isActive={this.state.isActive}
          images={this.state.images}
          handleClose={this.handleClose}
          handleSelected={image => {
            this.setState({
              coverImage: {
                article_image_id: image.id,
                src: image.thumbnail_src
              },
              isActive: false
            });
          }}
          searchByImageId={(articleImageId) => this.searchByImageId(articleImageId)}
        />
      </>
    );
  }
}
