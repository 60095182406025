import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '../../../atoms/SearchIcon';
import BaseSearchBox from '../../../../../common/molecules/Header/BaseSearchBox';

export default class SearchBox extends BaseSearchBox {
  render () {
    return (
      <div className={`p-header-responsive__search js_search ${this.formClassName()}`}>
        <form className="p-header-responsive__search__form">
          <div className="p-header-responsive__search_inner">
            <div className="p-header-responsive__input">
              <input className="p-header-responsive__search__form__input" placeholder="メディカルノートを検索" type="search" value={this.state.searchWord} onChange={this.onChangeSearchWord}/>
              <div className="p-header-responsive__pc-only">
                <SearchIcon />
              </div>
            </div>
            <a className="p-header-responsive__search_button" onClick={this.onClickSearch}>
              <SearchIcon />
            </a>
            <div className="p-header-responsive__submit">
              <button type="submit" onClick={this.onClickSearch}>検索</button>
            </div>
            <div className="p-header-responsive__cancel">
              <a className="js_search_close" onClick={this.props.onClickCloseSearchBox}>キャンセル</a>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

SearchBox.propTypes = {
  isOpenSearchBox: PropTypes.bool.isRequired,
  onClickCloseSearchBox: PropTypes.func.isRequired
};
