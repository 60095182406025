import React from 'react';
import PropTypes from 'prop-types';
import LogInConfirmComponent from '../../../../common/molecules/LogInConfirmComponent';
import LoggedInArticleBody from './LoggedInArticleBody';
import NotLoggedInArticleBody from './NotLoggedInArticleBody';

export default class LogInRequiredArticleBody extends LogInConfirmComponent {
  render () {
    return (
      <>
        {this.isLoggedIn() === null
          ? null
          : this.isLoggedIn()
            ? <LoggedInArticleBody
              articleBody={this.props.articleBody}
            />
            : <NotLoggedInArticleBody
              notLoggedInArticleBodyText={this.buildNotLoggedInArticleBodyText(this.props.articleBody)}
              loginPath={this.props.loginPath}
            />
        }
      </>
    );
  }
}

LogInRequiredArticleBody.propTypes = {
  articleBody: PropTypes.string.isRequired,
  loginPath: PropTypes.string.isRequired
};
