export default class JapaneseSyllabaryLines {
  // regexの値では、各行の文字をUnicodeプロパティの正規表現で指定している
  static japaneseSyllabaryLines = [
    { lineName: 'あ行', regex: /^[\u3041-\u304A\u3094]$/u },
    { lineName: 'か行', regex: /^[\u304B-\u3054\u3095\u3096]$/u },
    { lineName: 'さ行', regex: /^[\u3055-\u305D]$/u },
    { lineName: 'た行', regex: /^[\u305F-\u3069]$/u },
    { lineName: 'な行', regex: /^[\u306A-\u306E]$/u },
    { lineName: 'は行', regex: /^[\u306F-\u307D]$/u },
    { lineName: 'ま行', regex: /^[\u307E-\u3082]$/u },
    { lineName: 'や行', regex: /^[\u3083-\u3088]$/u },
    { lineName: 'ら行', regex: /^[\u3089-\u308D]$/u },
    { lineName: 'わ行', regex: /^[\u308E-\u3092]$/u },
    { lineName: 'その他', regex: /[^\u3041-\u3092\u3094-\u3096]/u }
  ];

  static all () {
    return this.japaneseSyllabaryLines;
  }

  static withoutOthers () {
    return this.japaneseSyllabaryLines.filter(line => line.lineName !== 'その他');
  }
}
