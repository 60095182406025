import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import {
  AbstractComponent
} from '../atoms/index';

export default class MustacheArticle extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      mustacheArticle: ''
    };
    this.fetchOptions = this.fetchOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  fetchOptions (inputValue) {
    return new Promise((resolve) => {
      const url = `${this.props.suggestUrl}${inputValue}`;
      this.fetch(url).then(data => { resolve(data.data); });
    });
  }

  handleChange (value) {
    value.index = this.props.i;
    this.setState({ mustacheArticle: value });
    this.props.handleChangeMustacheArticles(value, this.props.i);
  }

  render () {
    return (
      <div className="mr-4" style={{ width: '100%' }}>
        <AsyncSelect
          loadOptions={this.fetchOptions}
          onChange={this.handleChange}
          value={this.state.mustacheArticle}
          defaultInputValue={this.state.mustacheArticle.label}
        />
      </div>
    );
  }
}

MustacheArticle.propTypes = {
  i: PropTypes.number.isRequired,
  suggestUrl: PropTypes.string.isRequired,
  handleChangeMustacheArticles: PropTypes.func.isRequired
};

MustacheArticle.defaultProps = {
};
