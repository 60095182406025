import React from 'react';
import PropTypes from 'prop-types';
import LogInConfirmComponent from '../../../../common/molecules/LogInConfirmComponent';

class FooterRegistrationLink extends LogInConfirmComponent {
  render () {
    return (
      !this.isLoggedIn() && (
        <div className="l-signup-responsive">
          <div className="p-signup-responsive">
            <p className="p-signup-responsive__catch">
              メディカルノートの会員になれば<br />医療に関する様々なサービスを<br className="sp-only" />さらにお使い頂けます
            </p>
            <a href={this.props.signUpPath} className="p-signup-responsive__btn">
              <span>会員登録する</span>
            </a>
          </div>
        </div>
      )
    );
  }
}

FooterRegistrationLink.propTypes = {
  signUpPath: PropTypes.string.isRequired
};

export default FooterRegistrationLink;
